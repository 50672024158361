import {ref} from 'vue';
import getData from "@/api/getData/getData";
import {supportTickets} from "@/api/url/supportTicketUrls";

export function useSupportTicket() {
    const openTickets = ref()
    const closeTickets = ref()
    const allTickets = ref()
    const isLoading = ref(true)

    getData(supportTickets).then((response) => {
        if (response.request.readyState === 4) {
            openTickets.value = response.data[0].openTickets
            closeTickets.value = response.data[1].closedTickets
            allTickets.value = [...response.data[0].openTickets, ...response.data[1].closedTickets]
            isLoading.value = false
        }
    })

    return {
        openTickets,
        closeTickets,
        allTickets,
        isLoading
    }
}