<template>
    <form>
        <Dialog header="Создание обращения " :visible="displayPosition" :style="{width: '50vw'}" :position="position"
                closable :modal="true">
            <div class="p-grid p-fluid">
                <div class="p-col">
                    <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-user"></i>
                    </span>
                        <InputText
                            id="userFullName"
                            placeholder="ФИО"
                            v-model="userFullName"
                            @blur="userFullNameBlur"
                            :class="{'p-invalid':userFullNameError}"
                        />
                    </div>
                    <small v-if="userFullNameError" class="p-error ">{{ userFullNameError }}</small>
                </div>
            </div>

            <div class="p-grid p-fluid">
                <div class="p-col">
                    <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-phone"></i>
                    </span>
                        <InputText
                            id="userNumber"
                            placeholder="Номер телефона"
                            v-model="userNumber"
                            @blur="userNumberBlur"
                            :class="{'p-invalid':userNumberError}"
                        />
                    </div>
                    <small v-if="userNumberError" class="p-error ">{{ userNumberError }}</small>
                </div>
                <div class="p-col">
                    <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-envelope"></i>
                    </span>
                        <InputText
                            id="userEmail"
                            placeholder="Введите email"
                            v-model="userEmail"
                            @blur="userEmailBlur"
                            :class="{'p-invalid':userEmailError}"
                        />
                    </div>
                    <small v-if="userEmailError" class="p-error ">{{ userEmailError }}</small>
                </div>
            </div>

            <Divider align="center">
                <div class="p-d-inline-flex p-ai-center">
                    <i class="pi pi-question p-mr-2"></i>
                    <b>Задать вопрос службе технической поддержки</b>
                </div>
            </Divider>

            <div class="p-grid p-fluid p-col-12">
                <label for="theme" class="p-mb-2">Тема</label>
                <InputText
                    id="theme"
                    placeholder="Опишите вашу пробелему в двух словах..."
                    v-model="theme"
                    @blur="themeBlur"
                    :class="{'p-invalid':themeError}"
                />
                <small v-if="themeError" class="p-error ">{{ themeError }}</small>
            </div>
            <div class="p-grid p-fluid p-col-12">
                <label for="message" class="p-mb-2">Сообщение</label>
                <Textarea
                    id="message"
                    placeholder="Подробно расскажите о Вашей проблеме..."
                    v-model="message"
                    @blur="messageBlur"
                    :class="{'p-invalid':messageError}"
                    :autoResize="true"
                />
                <small v-if="messageError" class="p-error ">{{ messageError }}</small>
            </div>

            <div class="p-grid p-fluid">
                <div class="p-col-12">
                    <h6>Файл</h6>
                </div>
                <div class="p-col-12">
                    <div class="p-inputgroup">
                        <FileUpload
                            mode="basic"
                            name="demo[]"
                            url="./upload.php"
                            accept="image/*"
                            :maxFileSize="1000000"
                            @upload="onUpload"/>
                    </div>
                </div>
            </div>

            <div class="p-grid p-mt-5">
                <div class="p-col">
                    <div class="p-field-checkbox">
                        <Checkbox
                            id="binary"
                            v-model="checked"
                            :binary="true"
                        />
                        <label for="binary">Принимаю Согласие в рамках политики компании в отношении обработки
                            персональных
                            данных</label>
                    </div>
                </div>
            </div>
            <template #footer>
                <Button label="Закрыть" icon="pi pi-times" @click="closeModal" class="p-button-text p-button-danger"/>
                <Button label="Отправить" icon="pi pi-check" @click="addNewSupported" autofocus/>
            </template>
        </Dialog>
    </form>
</template>

<script>

import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Divider from "primevue/divider";
import Checkbox from "primevue/checkbox";
import Textarea from "primevue/textarea";
import FileUpload from "primevue/fileupload";
import {useFormDialogSupported} from "@/components/supportDialog/useFormDialogSupported";

export default {
    name: "SupportDialog",
    props: ['displayPosition', 'position'],
    setup(_, context) {
        const closeModal = () => {
            context.emit('closePosition', false)
        }

        return {
            closeModal,
            ...useFormDialogSupported(closeModal)
        }
    },

    components: {
        Dialog,
        Button,
        InputText,
        Divider,
        Checkbox,
        Textarea,
        FileUpload
    }

}
</script>

<style scoped>

</style>