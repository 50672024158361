<template>
    <div class="p-mt-1 p-mb-3">
        <div v-if="tickets?.length">
            <DataTable :value="tickets" responsiveLayout="scroll">
                <Column v-if="isLoading" field="data" header="Дата">
                    <template #body>
                        <Skeleton></Skeleton>
                    </template>
                </Column>
                <Column v-else field="data" header="Дата"></Column>
                <Column v-if="isLoading" field="theme" header="Тема обращение">
                    <template #body>
                        <Skeleton></Skeleton>
                    </template>
                </Column>
                <Column v-else field="theme" header="Тема обращение"></Column>
                <Column v-if="isLoading" field="message" header="Сообщение">
                    <template #body>
                        <Skeleton></Skeleton>
                    </template>
                </Column>
                <Column v-else field="message" header="Сообщение"></Column>
                <Column v-if="isLoading" field="status" header="Статус">
                    <template #body>
                        <Skeleton></Skeleton>
                    </template>
                </Column>
                <Column v-else field="status" header="Статус">
                    <template #body="slotProps">
                        <Tag v-if="slotProps.data.status === 'открыто'" class="p-mr-2" icon="pi pi-exclamation-triangle"
                             severity="warning" :value="slotProps.data.status"></Tag>
                        <Tag v-if="slotProps.data.status === 'закрыто'" class="p-mr-2" icon="pi pi-check"
                             severity="success" :value="slotProps.data.status"></Tag>
                    </template>
                </Column>
            </DataTable>
        </div>
        <div v-else>
            у вас нет обращений
        </div>
    </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Skeleton from "primevue/skeleton";
import Tag from 'primevue/tag';

export default {
    name: "SupportTicket",
    props: ['tickets', 'isLoading'],

    components: {
        DataTable,
        Column,
        Skeleton,
        Tag,
    }
}
</script>

<style scoped>

</style>