<template>
    <div class="p-grid">
        <div class="p-col">
            <form>
                <div class="p-grid">
                    <div class="p-col">
                        <h5 class="payment-method-title">Выберите способ оплаты</h5>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-xl-7 p-lg-7 p-md-7 p-sm-12">
                        <div class="p-field-radiobutton">
                            <RadioButton id="bankCard" name="bankCard" value="bankCard" v-model="paymentMethod"/>
                            <label for="bankCard" class="pay-label">Банковской картой</label>
                        </div>
                    </div>
                    <div class="p-xl-5 p-lg-5 p-md-5 p-sm-12">
                        <img src="@/assets/payment/visa-48.png" alt="" class="p-mr-3">
                        <img src="@/assets/payment/mastercard-48.png" alt="" class="p-mr-3">
                        <img src="@/assets/payment/mir-48.png" alt="" class="p-mr-3">
                        <img src="@/assets/payment/jcb-48.png" alt="" class="p-mr-3">
                    </div>
                </div>
                <div class="p-grid">
                    <div class="-xl-7 p-lg-7 p-md-7 p-sm-12">
                        <div class="p-field-radiobutton">
                            <RadioButton id="uMoney" name="uMoney" value="uMoney" v-model="paymentMethod"/>
                            <label for="uMoney" class="pay-label">ЮMoney</label>
                        </div>
                    </div>
                    <div class="p-xl-5 p-lg-5 p-md-5 p-sm-12">
                        <img src="@/assets/payment/icons8-google-pay-48.png" alt="" class="p-mr-3">
                        <img src="@/assets/payment/visa-48.png" alt="" class="p-mr-3">
                        <img src="@/assets/payment/mastercard-48.png" alt="" class="p-mr-3">
                        <img src="@/assets/payment/mir-48.png" alt="" class="p-mr-3">
                        <img src="@/assets/payment/io.png" alt="" class="p-mr-3"
                             style="{height:30px; width:40px; margin-bottom:10px;}">
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-7">
                        <div class="p-field-radiobutton">
                            <RadioButton id="fastPay" name="fastPay" value="fastPay" v-model="paymentMethod"
                                         :disabled="true"/>
                            <label for="fastPay" class="fast-pay-label">Система быстрых платежей</label>
                        </div>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col">
                        <h5>Введите сумму платежа</h5>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-7">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">$</span>
                            <InputText placeholder="Сумма" v-model="paymentCount"/>
                        </div>
                    </div>
                    <div class="p-col">
                        <Button label="Оплатить" @click="sendPayment"/>
                    </div>
                </div>
            </form>
        </div>
    </div>

</template>

<script>
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import Button from 'primevue/button';
import {usePaymentMethod} from "./usePaymentMethod";

export default {
    setup() {
        return {
            ...usePaymentMethod()
        }
    },

    components: {
        RadioButton,
        InputText,
        Button,
    }
}
</script>

<style scoped>
.pay-label {
    color: #ffffff
}

.fast-pay-label {
    color: darkgray;
}

@media (max-width: 400px) {
    img {
        margin-right: 0 !important;
    }

    .payment-method-title {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}
</style>