import {ref, onMounted} from 'vue';
import getData from "@/api/getData/getData";
import {PaymentHistory} from "@/api/url/paymentHistoryUrls";

export function useHistoryTable() {
    onMounted(() => {
        loading.value = true;
        lazyParams.value = {
            first: 0,
            rows: dt.value.rows,
        };
        loadLazyData();
    })

    const customers = ref([]);

    const dt = ref();
    const loading = ref(false);
    const totalRecords = ref(0);
    const lazyParams = ref({});


    const loadLazyData = (page) => {
        loading.value = true;
        getData(PaymentHistory, page)
            .then((response) => {
                if (response.request.readyState === 4) {
                    customers.value = response.data.payments
                    totalRecords.value = response.data.countPages;
                    loading.value = false;
                }
            })
    };
    const onPage = (event) => {
        lazyParams.value = event;
        loadLazyData(event.page);
    };

    return {
        customers,
        dt,
        loading,
        totalRecords,
        lazyParams,
        loadLazyData,
        onPage,
    }
}