import {useField} from "vee-validate";
import {useForm} from "vee-validate";
import * as yup from "yup";
import {ref} from "vue";
import postModalNewSupported from "@/api/support/postModalNewSupported";

export function useFormDialogSupported(closeModal) {

    const checked = ref(false);
    const {handleSubmit} = useForm()

    const {
        value: userFullName, errorMessage: userFullNameError, handleBlur: userFullNameBlur
    } = useField('userFullName',
        yup.string().trim().required('Поле не может быть пустым')
    )
    const {value: userNumber, errorMessage: userNumberError, handleBlur: userNumberBlur} = useField('userNumber',
        yup.string().trim().required('Поле не может быть пустым')
    )
    const {value: userEmail, errorMessage: userEmailError, handleBlur: userEmailBlur} = useField('userEmail',
        yup.string().trim().email()
    )
    const {value: theme, errorMessage: themeError, handleBlur: themeBlur} = useField('theme',
        yup.string().required('Поле не может быть пустым')
    )
    const {value: message, errorMessage: messageError, handleBlur: messageBlur} = useField('message',
        yup.string().required('Поле не может быть пустым')
    )

    const onUpload = () => {
        console.log('good')
    }

    const addNewSupported = handleSubmit(async (values, {resetForm}) => {
        await postModalNewSupported(values).then((response) => {
            if (response.request.readyState === 4) {
                closeModal()
                resetForm()
            }
        })
    })

    return {
        userFullName,
        userFullNameError,
        userFullNameBlur,
        userNumber,
        userNumberError,
        userNumberBlur,
        checked,
        userEmail,
        userEmailError,
        userEmailBlur,
        theme,
        themeError,
        themeBlur,
        message,
        messageError,
        messageBlur,
        addNewSupported,
        onUpload
    }
}