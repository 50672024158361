import {onMounted, ref} from "vue";
import getData from "@/api/getData/getData";
import {PaymentHistoryStatistic} from "@/api/url/paymentStatisticUrls";

export function useDiagram() {
    const chartData = ref()

    onMounted(() => {
        getData(PaymentHistoryStatistic)
            .then((response) => {
                chartData.value = {
                    labels: response.data.labels.map((item) => item.label),
                    datasets: response.data.datasets,
                }
            })
    })

    const lightOptions = ref({
        plugins: {
            legend: {
                labels: {
                    color: '#ffffff'
                }
            }
        }
    });

    return {chartData, lightOptions}
}