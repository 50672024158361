import {ref} from "vue";
import getData from "@/api/getData/getData";
import {offers} from "@/api/url/offersUrls";

export const useOffers = () => {
    const products = ref();
    const isLoading = ref(true)

    getData(offers).then((response) => {
        if (response.request.readyState === 4) {
            products.value = response.data
            isLoading.value = false;
        }
    })

    return {
        products,
        isLoading
    }
}