<template>
<span class="p-input-icon-left p-mr-3">
    <i class="pi pi-search"/>
    <InputText type="text" placeholder="Поиск" class="search"/>
</span>
</template>

<script>
import InputText from "primevue/inputtext";

export default {
    components: {
        InputText
    }
}
</script>

<style scoped>
@media (max-width: 1020px) {
    .search {
        width: 120px;
    }
}

@media (max-width: 400px) {
    .search {
        width: 110px;
    }
}
</style>