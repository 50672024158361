import {ref} from "vue";
import changePhone from "@/api/changeContactData/changePhone";
import confirmPhone from "@/api/changeContactData/confirmPhone";
import {useField, useForm} from "vee-validate";
import * as yup from "yup";

export function useChangePhone() {
    const phoneSent = ref(false);
    const displayPhoneModal = ref(false);

    const closePhoneModal = () => {
        displayPhoneModal.value = false;
    }
    const openPhoneModal = () => {
        displayPhoneModal.value = true;
    };

    const {handleSubmit, resetForm} = useForm()

    const sendPhone = handleSubmit((values) => {
        changePhone(values).then((response) => {
            if (response.request.readyState === 4) {
                phoneSent.value = true
            }
        })
    })

    const sendPhoneCode = () => {
        confirmPhone(phoneCode.value).then((response) => {
            if (response.request.readyState === 4) {
                resetPhoneForm()
            }
        })
    }

    const resetPhoneForm = () => {
        phoneSent.value = false;
        displayPhoneModal.value = false;
    }

    const {value: newPhone, errorMessage: newPhoneErrorMessage} = useField('new-phone',
        yup
            .string()
            .matches(/^\d{10}$|^$/, 'Неверный формат номера')
            .trim()
            .required('Поле не может быть пустым')
    )

    const {value: phoneCode, errorMessage: phoneCodeErrorMessage} = useField('phone-code',
        yup
            .string()
            .trim()
    )

    return {
        newPhone,
        newPhoneErrorMessage,
        phoneCode,
        phoneCodeErrorMessage,
        displayPhoneModal,
        phoneSent,
        sendPhone,
        sendPhoneCode,
        resetPhoneForm,
        openPhoneModal,
        closePhoneModal,
        resetForm
    }
}