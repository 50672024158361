<template>
    <Dialog header="Новое обращение" v-model:visible="displayModal" :style="{width: '50vw'}" :modal="true">
        <form>
            <div class="p-fluid">
                <div class="p-field">
                    <Dropdown
                        v-model="selectedFAQTheme"
                        @blur="blurSelectedFAQTheme"
                        :class="{'p-invalid': errorSelectedFAQTheme }"
                        placeholder="Выберите тему"
                        :options="themes"
                        optionLabel="name"
                        optionValue="code"
                        id="FAQ-themes"
                    />
                </div>
                <div class="p-field">
                     <Textarea
                         id="FAQ-text"
                         placeholder="Введите текст обращения"
                         v-model="textFaqAppeal"
                         @blur="blurTextFaqAppeal"
                         :class="{'p-invalid': errorTextFaqAppeal }"
                         rows="5"
                     />
                    <small v-if="errorTextFaqAppeal" class="p-error ">{{ errorTextFaqAppeal }}</small>
                </div>
                <div class="p-field">
                    <FileUpload
                        name="demo[]"
                        url="./upload.php"
                        @upload="onUpload"
                        :multiple="true"
                        accept="image/*"
                        :maxFileSize="1000000"
                        :showUploadButton="false"
                    >
                        <template #empty>
                            <p>Перетащите сюда документы</p>
                        </template>
                    </FileUpload>
                </div>
            </div>
        </form>
        <template #footer>
            <Button label="Отправить" icon="pi pi-check" @click="sendFaqAppeal" autofocus/>
        </template>
    </Dialog>
</template>

<script>
import useFaqModal from './useFaqModal'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import FileUpload from 'primevue/fileupload';

export default {
    props: {
        isOpenModal: {
            type: Object
        },
        onUpload: {
            type: Function,
        }
    },
    setup(props) {
        return {
            ...useFaqModal(props.isOpenModal, props.onUpload)
        }
    },
    components: {
        Button,
        Dialog,
        Dropdown,
        Textarea,
        FileUpload
    }
}
</script>

<style scoped>

</style>