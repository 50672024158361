import {ref} from "vue";
import getData from "@/api/getData/getData";
import {contractInfo, suspendedContract} from "@/api/url/contractsUrls";

export function useContracts() {
    const position = ref('center');
    const info = ref([])
    const suspendedInfo = ref([])
    const displayPosition = ref(false);

    getData(contractInfo).then((response) => {
        info.value = response.data
    })
    getData(suspendedContract).then((response) => {
        suspendedInfo.value = response.data
    })

    const closePosition = (value) => {
        displayPosition.value = value;
    };

    const openPosition = (pos) => {
        position.value = pos;
        displayPosition.value = true;
    };

    return {
        info,
        suspendedInfo,
        position,
        displayPosition,
        closePosition,
        openPosition,
    }
}