<template>
    <div v-if="isLoading" class="p-col">
        <h4 class="offers-title">>Персональные предложения</h4>
        <Skeleton height="319px" borderRadius="16px"></Skeleton>
    </div>
    <div v-else class="card p-col">
        <h4 class="offers-title">Персональные предложения</h4>
        <div v-if="products.length <= 3" class="p-grid offers-container">
            <div class="p-lg-6 p-md-6 p-sm-12 p-col-12" v-for="product in products" :key="product.id">
                <OffersItem :product="product"/>
            </div>
        </div>
        <Carousel v-else :value="products" :numVisible="3" :numScroll="3" :responsiveOptions="offersCarousel">
            <template #item="slotProps">
                <OffersItemSlider
                    :slotProps="slotProps"
                >
                </OffersItemSlider>
            </template>
        </Carousel>
    </div>
</template>

<script>

import {ref} from "vue";
import {useOffers} from "./useOffers";
import {offersCarouselOptions} from './offersCarouselOptions'
import Carousel from 'primevue/carousel';
import OffersItem from './OffersItem'
import OffersItemSlider from './OffersItemSlider'
import Skeleton from 'primevue/skeleton'

export default {
    setup() {
        const offersCarousel = ref(offersCarouselOptions)
        return {
            ...useOffers(),
            offersCarousel,
        }
    },

    components: {
        Carousel,
        OffersItem,
        OffersItemSlider,
        Skeleton
    }
}
</script>

<style scoped>
.offers-title {
    color: var(--yellow-500);
}

.offers-container {
    padding-left: 35px;
    padding-right: 35px;
}

@media (max-width: 1200px) {
    .offers-container {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 992px) {
    .offers-title {
        margin-top: 0;
    }
}

@media (max-width: 500px) {
    .offers-title {
        font-size: 1.1rem;
    }
}
</style>