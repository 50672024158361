<template>
    <a href="/">
        <div class="p-grid p-pl-2">
            <img src="@/assets/img/logo-not-fon.png" alt="logo" class="img-logo-header p-mt-2 p-mr-2">
        </div>
    </a>
</template>

<script>
export default {}
</script>

<style scoped>
.img-logo-header {
    height: 50px;
}
</style>