<template>
    <div class="p-col-12 block-footer">
        <div class="p-grid p-jc-center p-ai-center footer">
            <div
                class="p-grid p-xl-4 p-lg-4 p-md-4 p-sm-12 p-col-12 p-justify-center phone-footer p-text-center p-flex-column">
                <p>Служба круглосуточной поддержки клиентов: 8-800-222-96-96 </p>
                <p> Отдел по работе с абонентами: (81153) 6-90-00</p>
            </div>
            <div
                class="p-grid p-xl-4 p-lg-4 p-md-4 p-sm-12 p-col-12 p-justify-center copyright-footer p-text-center p-flex-column">
                <p>Все права защищены</p>
                <p>Группа компаний МАРТ &copy; 2021</p>
            </div>
            <div class="p-grid p-xl-4 p-lg-4 p-md-4 p-sm-12 p-col-12 p-justify-center">
                <ul class="social-list">
                    <li><a href="#" class="vk"><i class="fab fa-vk social-icon"></i></a></li>
                    <li><a href="#" class="odnoklassniki"><i class="fab fa-odnoklassniki social-icon"></i></a></li>
                    <li><a href="#" class="instagram"><i class="fab fa-instagram social-icon"></i></a></li>
                    <li><a href="#" class="facebook"><i class="fab fa-facebook-f social-icon"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import '@fortawesome/fontawesome-free/js/all.js';

export default {}
</script>

<style scoped>
.block-footer {
    margin-bottom: -7px;
    padding-bottom: 0;
}

.footer {
    align-content: center;
    color: white;
    height: auto !important;
    background-color: #1f2d40;
    font-size: 12px;
    margin-right: -7px;
    margin-left: -7px;
}

.phone-footer {
    margin-top: 0;
}

.phone-footer > p {
    margin-bottom: 0;
}

.copyright-footer {
    text-align: center;
    margin-top: 0;
}

.copyright-footer > p {
    margin-bottom: 0;
}

ul {
    margin-top: 6px;
    margin-bottom: 0;
    padding-left: 0;
}

ul li {
    list-style: none;
    float: left;
    margin-right: 7px;
}

ul li a {
    display: block;
    position: relative;
    text-align: center;
    color: white;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    line-height: 38px;
    transition: .5s;
    border: 2px solid #a8a9ab;
}

ul li a.vk:hover {
    color: #6287ae;
    box-shadow: 0 0 5px #6287ae;
}

ul li a.odnoklassniki:hover {
    color: #ed812b;
    box-shadow: 0 0 5px #ed812b;
}

ul li a.instagram:hover {
    color: #8359ee;
    box-shadow: 0 0 5px #8359ee;
}

ul li a.facebook:hover {
    color: #3f67bc;
    box-shadow: 0 0 5px #3f67bc;
}

.social-icon {
    font-size: 1.6em;
}

</style>