<template>
    <DataTable :value="customers"
               :paginator="true"
               :rows="5"
               :pageLinkSize="10"
               ref="dt"
               :totalRecords="totalRecords"
               :loading="loading"
               @page="onPage($event)"
               paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
               responsiveLayout="scroll"
    >
        <Column field="paymentMethod" header="Способ оплаты"></Column>
        <Column field="type" header="Тип"></Column>
        <Column field="date" header="Дата"></Column>
        <Column field="sum" header="Сумма"></Column>
        <template #paginatorLeft>
        </template>
        <template #paginatorRight>
        </template>
    </DataTable>
</template>

<script>
import {useHistoryTable} from "./useHistoryTable";
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

export default {
    setup() {
        return {
            ...useHistoryTable()
        }
    },
    components: {
        DataTable,
        Column,
    }
}
</script>

<style scoped>

</style>