<template>
    <Accordion :activeIndex="0">
        <AccordionTab>
            <template #header>
                <i class="pi pi-lock-open accordion-icon-header"></i>
                <span class="accordion-header">Открытые обращения</span>
            </template>
            <SupportTicket :tickets="openTickets" :isLoading="isLoading"/>
        </AccordionTab>
        <AccordionTab>
            <template #header>
                <i class="pi pi-lock accordion-icon-header"></i>
                <span class="accordion-header">Закрытые обращения</span>
            </template>
            <SupportTicket :tickets="closeTickets" :isLoading="isLoading"/>
        </AccordionTab>
        <AccordionTab>
            <template #header>
                <i class="pi pi-list accordion-icon-header"></i>
                <span class="accordion-header">Все обращения</span>
            </template>
            <SupportTicket :tickets="allTickets" :isLoading="isLoading"/>
        </AccordionTab>
    </Accordion>
</template>

<script>
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import SupportTicket from "@/components/accordions/support-accordion/SupportTicket";
import {useSupportTicket} from "@/components/accordions/support-accordion/useSupportTicket";

export default {
    name: "SupportAccordion",
    setup() {
        return {
            ...useSupportTicket()
        }
    },
    components: {
        Accordion,
        AccordionTab,
        SupportTicket,
    }
}
</script>

<style scoped>
.accordion-header {
    margin-left: 10px;
    color: #686dce;
}

.accordion-icon-header {
    color: #686dce;
}
</style>