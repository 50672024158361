<template>
    <div class="p-grid p-mt-3">
        <div class="p-col-12">
            <TabView>
                <TabPanel>
                    <template #header>
                        <i class="pi pi-user"></i>
                        <span class="title_tab">Мои данные</span>
                    </template>
                    <MyDataTab/>
                </TabPanel>

                <TabPanel>
                    <template #header>
                        <i class="pi pi-credit-card"></i>
                        <span class="title_tab">Привязанные карты</span>
                    </template>
                    <LinkedCardsTab/>
                </TabPanel>

                <TabPanel>
                    <template #header>
                        <i class="pi pi-lock"></i>
                        <span class="title_tab">Пароли и авторизация</span>
                    </template>
                    <p>Пароли и авторизация</p>
                </TabPanel>

                <TabPanel>
                    <template #header>
                        <i class="pi pi-send"></i>
                        <span class="title_tab">Соцсети</span>
                    </template>
                    <p>Соцсети</p>
                </TabPanel>

                <TabPanel>
                    <template #header>
                        <i class="pi pi-clock"></i>
                        <span class="title_tab">История действий</span>
                    </template>
                    <ActionHistory/>
                </TabPanel>
            </TabView>
        </div>
    </div>
</template>

<script>
import MyDataTab from "@/components/dashboard/profile/tabs/myDataTab/MyDataTab";
import LinkedCardsTab from "@/components/dashboard/profile/tabs/linkedCardsTab/LinkedCardsTab";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ActionHistory from "@/components/dashboard/profile/tabs/actionHistoryTab/ActionHistory";

export default {
    components: {
        ActionHistory,
        MyDataTab,
        LinkedCardsTab,
        TabView,
        TabPanel,
    }
}
</script>

<style scoped>
i.pi {
    margin-right: 0.5rem;
}

@media (max-width: 772px) {
    .title_tab {
        display: none;
    }
}

</style>