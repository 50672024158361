import {ref} from "vue";
import getData from "@/api/getData/getData";
import {notifications} from "@/api/url/notificationHeaderUrls";

export function useNotifications() {
    const items = ref([]);
    const op = ref();

    getData(notifications).then((res) => {
        console.log(res.data)
        items.value = res.data
    })
    const clearNotification = () => {
        items.value = []
    }
    const toggle = (event) => {
        op.value.toggle(event);
    };

    return {
        items,
        op,
        clearNotification,
        toggle,
    }
}
