<template>
    <form>
        <Dialog header="Заявка на подключение " :visible="displayPosition" :style="{width: '50vw'}" :position="position"
                closable :modal="true">
            <div class="p-grid p-fluid">
                <div class="p-col">
                    <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-user"></i>
                    </span>
                        <InputText
                            id="userName"
                            placeholder="Ваше Имя"
                            v-model="userName"
                            @blur="userNameBlur"
                            :class="{'p-invalid':userNameError}"
                        />
                    </div>
                    <small v-if="userNameError" class="p-error ">{{ userNameError }}</small>
                </div>
                <div class="p-col">
                    <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-user"></i>
                    </span>
                        <InputText
                            id="userLastName"
                            placeholder="Ваша Фамилия"
                            v-model="userLastName"
                            @blur="userLastNameBlur"
                            :class="{'p-invalid':userLastNameError}"
                        />
                    </div>
                    <small v-if="userLastNameError" class="p-error ">{{ userLastNameError }}</small>
                </div>
            </div>
            <div class="p-grid p-fluid p-mb-3">
                <div class="p-col">
                    <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-user"></i>
                    </span>
                        <InputText
                            id="userPatronymic"
                            placeholder="Ваше Отчество"
                            v-model="userPatronymic"
                            @blur="userPatronymicBlur"
                            :class="{'p-invalid':userPatronymicError}"
                        />
                    </div>
                    <small v-if="userPatronymicError" class="p-error ">{{ userPatronymicError }}</small>
                </div>
                <div class="p-col">
                    <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-android"></i>
                    </span>
                        <InputText
                            id="userNumber"
                            placeholder="Контактный телефон"
                            v-model="userNumber"
                            @blur="userNumberBlur"
                            :class="{'p-invalid':userNumberError}"
                        />
                    </div>
                    <small v-if="userNumberError" class="p-error ">{{ userNumberError }}</small>
                </div>
            </div>
            <Divider align="center">
                <div class="p-d-inline-flex p-ai-center">
                    <i class="pi pi-globe p-mr-2"></i>
                    <b>Введите адрес, который необходимо подключить</b>
                </div>
            </Divider>
            <div class="p-grid p-fluid p-mt-3">
                <div class="p-col">
                    <div class="p-inputgroup">
                        <Dropdown
                            id="city"
                            placeholder="Город"
                            v-model="selectedCity"
                            :options="cities"
                            optionLabel="name"
                            optionValue="code"
                            @blur="selectedCityBlur"
                            :class="{'p-invalid':selectedCityError}"
                        />
                    </div>
                    <small v-if="selectedCityError" class="p-error ">{{ selectedCityError }}</small>
                </div>
                <div class="p-col">
                    <div class="p-inputgroup">
                        <Dropdown
                            id="selectedStreetType"
                            placeholder="Тип улицы"
                            v-model="selectedStreetType"
                            :options="streetType"
                            optionLabel="name"
                            optionValue="code"
                            @blur="streetTypeBlur"
                            :class="{'p-invalid':streetTypeError}"
                        />
                    </div>
                    <small v-if="streetTypeError" class="p-error ">{{ streetTypeError }}</small>
                </div>
                <div class="p-col">
                    <div class="p-inputgroup">
                        <InputText
                            id="streetName"
                            placeholder="Название улицы"
                            v-model="streetName"
                            @blur="streetNameBlur"
                            :class="{'p-invalid':streetNameError}"
                        />
                    </div>
                    <small v-if="streetNameError" class="p-error ">{{ streetNameError }}</small>
                </div>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col">
                    <div class="p-inputgroup">
                        <InputText
                            id="houseNumber"
                            placeholder="№ дома"
                            v-model="houseNumber"
                            @blur="houseNumberBlur"
                            :class="{'p-invalid':houseNumberError}"
                        />
                    </div>
                    <small v-if="houseNumberError" class="p-error ">{{ houseNumberError }}</small>
                </div>
                <div class="p-col">
                    <div class="p-inputgroup">
                        <InputText
                            id="buildingNumber"
                            placeholder="№ корпуса"
                            v-model="buildingNumber"
                            @blur="buildingNumberBlur"
                            :class="{'p-invalid':buildingNumberError}"
                        />
                    </div>
                    <small v-if="buildingNumberError" class="p-error ">{{ buildingNumberError }}</small>
                </div>
                <div class="p-col">
                    <div class="p-inputgroup">
                        <InputText
                            id="apartmentNumber"
                            placeholder="№ квартиры"
                            v-model="apartmentNumber"
                            @blur="apartmentNumberBlur"
                            :class="{'p-invalid':apartmentNumberError}"
                        />
                    </div>
                </div>
                <small v-if="apartmentNumberError" class="p-error ">{{ apartmentNumberError }}</small>
            </div>
            <div class="p-grid p-mt-5">
                <div class="p-col">
                    <div class="p-field-checkbox">
                        <Checkbox
                            id="binary"
                            v-model="checked"
                            :binary="true"
                        />
                        <label for="binary">Принимаю Согласие в рамках политики компании в отношении обработки
                            персональных данных</label>
                    </div>
                </div>
            </div>
            <template #footer>
                <Button label="Закрыть" icon="pi pi-times" @click="closeModal" class="p-button-text p-button-danger"/>
                <Button label="Отправить" icon="pi pi-check" @click="addNewAddress" autofocus/>
            </template>
        </Dialog>
    </form>
</template>

<script>
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import InputText from "primevue/inputtext";
import Divider from "primevue/divider";
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import {useFormDialogContract} from "./useFormDialogContract";

export default {
    props: ['displayPosition', 'position'],
    setup(_, context) {
        const closeModal = () => {
            context.emit('closePosition', false)
        }

        return {
            closeModal,
            ...useFormDialogContract(closeModal)
        }
    },

    components: {
        Dialog,
        Button,
        InputText,
        Divider,
        Dropdown,
        Checkbox,
    }
}
</script>