export const items = [
    {
        id: 1,
        header: 'Новости вашего интернет-провайдера',
        content: 'О событиях, которые произошли в конце июня и о том, как мы их решаем.',
    },
    {
        id: 2,
        header: 'Восстановительные работы после урагана',
        content: 'Мы продолжаем работать над устранением последствий урагана. Подготовлен список адресов, где связь уже восстановлена.',
    },
    {
        id: 3,
        header: 'Приглашаем на работу',
        content: 'Группа компаний МАРТ приглашает на постоянную работу сотрудников для работы по монтажу, настройке и техническому сопровождению оборудования ГЛОНАСС и видеонаблюдения на транспорте.',
    },
    {
        id: 4,
        header: 'Что значит значок "РАКЕТА"?',
        content: 'Увидели на карте покрытия ГК Март знак с изображением ракеты на своем доме? Это значит, что Вы можете подключить самый быстрый тариф из линейки Фристайл.',
    },
    {
        id: 5,
        header: 'Обгони всех!',
        content: 'Новости про тариф с улетной скоростью Фристайл-1000.',
    },
    {
        id: 6,
        header: ' Планы на 2021 год',
        content: 'О том, какие районы г. Великие Луки, Себежа и Новосокольники планируется подключить в этом году.',
    }, {
        id: 7,
        header: 'Обновление прошивки ТСПУ',
        content: 'Во вторник, 2 марта, в 03:00 будет производиться обновление прошивки ТСПУ, в связи с чем непродолжительное время будет отсутствовать доступ к сети Интернет у всех абонентов ГК Март',
    }, {
        id: 8,
        header: 'ПроTV: Новые телеканалы',
        content: 'Еще одна хорошая новость для тех, кто так давно ждал добавления каналов телекомпании «Ред Медиа».',
    }, {
        id: 9,
        header: 'ПроTV: для любителей спорта',
        content: 'По многочисленным просьбам мы добавили в телевизионную сетку вещания от ГК Март новые каналы.',
    }, {
        id: 10,
        header: ' Время отклика в online играх',
        content: 'В очередной статье специалисты технической поддержки ГК Март постараются объяснить, что время отклика в игре (и не только в игре) зависит от множества факторов.',
    }
]