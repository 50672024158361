<template>
    <Accordion :activeIndex="0">
        <AccordionTab>
            <template #header>
                <i class="pi pi-cog accordion-icon-header"></i>
                <span class="accordion-header">Техническая информация</span>
            </template>
            <TechnicalInfo/>
        </AccordionTab>
        <AccordionTab>
            <template #header>
                <i class="pi pi-question accordion-icon-header"></i>
                <span class="accordion-header">Когда необходимо оплачивать услуги?</span>
            </template>
            <p>Всегда иначе никак! не оплатишь услуги никакой тебе Доты и Контер страйка</p>
        </AccordionTab>
        <AccordionTab>
            <template #header>
                <i class="pi pi-android accordion-icon-header"></i>
                <span class="accordion-header">Просмотр IpTV на устройствах Android</span>
            </template>
            <p>Нужно скачать с Play-маркет приложение ip-tv, втавить плейлист с официального сайта марта и смотреть
                телек</p>
        </AccordionTab>
    </Accordion>
</template>

<script>
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import TechnicalInfo from "./TechnicalInfo";

export default {
    components: {
        Accordion,
        AccordionTab,
        TechnicalInfo,
    }
}
</script>

<style scoped>
.accordion-header {
    margin-left: 10px;
    color: #686dce;
}

.accordion-icon-header {
    color: #686dce;
}
</style>