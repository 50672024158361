<template>
    <Card class="payment-history_card">
        <template #title>
            <h4 class="payment-history__card-header">История платежей</h4>
        </template>
        <template #content>
            <div class="p-grid">
                <div class="p-xl-6 p-lg-6 p-md-6 p-sm-12 p-overflow-hidden">
                    <PaymentHistoryTable/>
                </div>
                <div class="p-xl-6 p-lg-6 p-md-6 p-sm-12 pie-diagram-payment">
                    <Diagram/>
                </div>
            </div>
        </template>
    </Card>
</template>

<script>
import PaymentHistoryTable from "./PaymentHistoryTable";
import Diagram from "../diagram/Diagram";
import Card from 'primevue/card';

export default {
    components: {
        Card,
        PaymentHistoryTable,
        Diagram,
    }
}
</script>

<style scoped>
.payment-history__card-header {
    color: #686dce;
}

.payment-history_card {
    width: 100%;
    margin-bottom: 2em;
    padding-left: 2em;
}

@media (max-width: 550px) {
    .pie-diagram-payment {
        display: none;
    }

    .payment-history_card {
        padding-left: 0;
    }
}
</style>