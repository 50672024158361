import {ref, onMounted} from 'vue';
import getData from "@/api/getData/getData";
import {ActionHistory} from "@/api/url/actionHistoryUrls";
import {FilterMatchMode} from 'primevue/api';

export function useActionHistoryTable() {
    onMounted(() => {
        loading.value = true;
        lazyParams.value = {
            first: 0,
            rows: dt.value.rows,
        };
        loadLazyData();
    })

    const history = ref([]);

    const dt = ref();
    const loading = ref(false);
    const totalRecords = ref(0);
    const lazyParams = ref({});

    const filters = ref({
        'actionCategory': {value: null, matchMode: FilterMatchMode.EQUALS},
    });

    const actionCategories = ref([
        'Финансовые действия', 'Административные действия', 'Действия с договорами', 'Действия с профилем'
    ]);

    const loadLazyData = (page) => {
        loading.value = true;
        getData(ActionHistory, page)
            .then((response) => {
                if (response.request.readyState === 4) {
                    history.value = response.data.actions;
                    totalRecords.value = response.data.countPages;
                    loading.value = false;
                }
            })
    };
    const onPage = (event) => {
        lazyParams.value = event;
        loadLazyData(event.page);
    };

    return {
        history,
        dt,
        loading,
        totalRecords,
        lazyParams,
        loadLazyData,
        onPage,
        filters,
        actionCategories
    }
}