import {ref} from "vue";
import {useField} from "vee-validate";
import {useForm} from "vee-validate";
import * as yup from "yup";
import postFaqForm from "@/api/FAQ/postFaqForm";

export default function useFaqModal(isOpenModal, onUpload) {

    const showSuccessToast = ref();
    const {handleSubmit, resetForm} = useForm()
    const displayModal = ref(isOpenModal);
    const themes = ref([
        {name: 'Технические вопросы', code: 'tech_question'},
        {name: 'Вопросы по оплате', code: 'money_question'},
        {name: 'Обратная связь по кабинету', code: 'feedback'},
        {name: 'Другое', code: 'other'},
    ])

    const {
        value: selectedFAQTheme,
        errorMessage: errorSelectedFAQTheme,
        handleBlur: blurSelectedFAQTheme
    } = useField('FAQ-themes',
        yup
            .string()
            .trim()
            .required()
    )

    const {value: textFaqAppeal, errorMessage: errorTextFaqAppeal, handleBlur: blurTextFaqAppeal} = useField('FAQ-text',
        yup
            .string()
            .trim()
            .required('Поле не может быть пустым')
    )

    const sendFaqAppeal = handleSubmit(async (values) => {
        await postFaqForm(values).then((response) => {
            if (response.request.readyState === 4) {
                displayModal.value = false
                resetForm()
                onUpload()
            }
        })
    })

    const closeModal = () => {
        displayModal.value = false;
    }

    return {
        displayModal,
        themes,
        closeModal,
        selectedFAQTheme,
        errorSelectedFAQTheme,
        blurSelectedFAQTheme,
        textFaqAppeal,
        errorTextFaqAppeal,
        blurTextFaqAppeal,
        sendFaqAppeal,
        resetForm,
        showSuccessToast
    }
}