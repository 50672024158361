export const infoWidgetsItems = [
    {
        color: 'rgba(41, 50, 65, 0.8)',
        colorTitle: 'rgba(41, 50, 65, 0.8)',
        backgroundTitle: 'rgb(255, 255, 255)',
        backgroundContent: 'rgb(255, 255, 255)',
        cssClass: 'white',
        widgetIcon: 'pi-money-bill'
    },
    {
        color: '#ffffff',
        colorTitle: '#ffffff',
        backgroundTitle: 'rgb(105, 183, 255)',
        backgroundContent: 'rgb(105, 183, 255)',
        cssClass: 'blue',
        widgetIcon: 'pi-sitemap'
    },
    {
        color: '#ffffff',
        colorTitle: '#ffffff',
        backgroundTitle: 'rgb(62, 70, 83)',
        backgroundContent: 'rgb(62, 70, 83)',
        cssClass: 'black',
        widgetIcon: 'pi-bell'
    },
    {
        color: '#ffffff',
        colorTitle: '#ffffff',
        backgroundTitle: 'rgb(255, 179, 64)',
        backgroundContent: 'rgb(255, 179, 64)',
        cssClass: 'yellow',
        widgetIcon: 'pi-send'
    },
]