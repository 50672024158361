import {useField} from "vee-validate";
import {useForm} from "vee-validate";
import * as yup from "yup";
import {ref} from "vue";
import {citiesConstant, streetTypesConstant} from "./dropdownValues";
import postModalNewAddress from "@/api/contracts/postModalNewAddress";

export function useFormDialogContract(closeModal) {

    const checked = ref(false);
    const {handleSubmit} = useForm()

    const cities = ref(citiesConstant);
    const streetType = ref(streetTypesConstant);

    const {value: userName, errorMessage: userNameError, handleBlur: userNameBlur} = useField('userName',
        yup.string().trim().required('Поле не может быть пустым')
    )
    const {
        value: userLastName,
        errorMessage: userLastNameError,
        handleBlur: userLastNameBlur
    } = useField('userLastName',
        yup.string().trim().required('Поле не может быть пустым')
    )
    const {
        value: userPatronymic,
        errorMessage: userPatronymicError,
        handleBlur: userPatronymicBlur
    } = useField('userPatronymic',
        yup.string().trim().required('Поле не может быть пустым')
    )
    const {value: userNumber, errorMessage: userNumberError, handleBlur: userNumberBlur} = useField('userNumber',
        yup.string().trim().required('Поле не может быть пустым')
    )
    const {value: selectedCity, errorMessage: selectedCityError, handleBlur: selectedCityBlur} = useField('city',
        yup.string().trim().required('Выберите город')
    )
    const {
        value: selectedStreetType,
        errorMessage: streetTypeError,
        handleBlur: streetTypeBlur
    } = useField('selectedStreetType',
        yup.string().trim().required('Выберите тип улицы')
    )
    const {value: streetName, errorMessage: streetNameError, handleBlur: streetNameBlur} = useField('streetName',
        yup.string().trim().required('Выберите название улицы')
    )
    const {value: houseNumber, errorMessage: houseNumberError, handleBlur: houseNumberBlur} = useField('houseNumber',
        yup.number('поддерживается только цифровое значение').required('Выберите номер дома').typeError('поддерживается только цифровое значение'),
    )
    const {
        value: buildingNumber,
        errorMessage: buildingNumberError,
        handleBlur: buildingNumberBlur
    } = useField('buildingNumber',
        yup.number('поддерживается только цифровое значение').typeError('поддерживается только цифровое значение'),
    )
    const {
        value: apartmentNumber,
        errorMessage: apartmentNumberError,
        handleBlur: apartmentNumberBlur
    } = useField('apartmentNumber',
        yup.number('поддерживается только цифровое значение').typeError('поддерживается только цифровое значение'),
    )

    const addNewAddress = handleSubmit(async (values, {resetForm}) => {
        await postModalNewAddress(values).then((response) => {
            if (response.request.readyState === 4) {
                closeModal()
                resetForm()
            }
        })
    })

    return {
        userName,
        userNameError,
        userNameBlur,
        userLastName,
        userLastNameError,
        userLastNameBlur,
        userPatronymic,
        userPatronymicError,
        userPatronymicBlur,
        userNumber,
        userNumberError,
        userNumberBlur,
        checked,
        selectedCity,
        selectedCityError,
        selectedCityBlur,
        selectedStreetType,
        streetTypeError,
        streetTypeBlur,
        cities,
        streetType,
        streetName,
        streetNameError,
        streetNameBlur,
        houseNumber,
        houseNumberError,
        houseNumberBlur,
        buildingNumber,
        buildingNumberError,
        buildingNumberBlur,
        apartmentNumber,
        apartmentNumberError,
        apartmentNumberBlur,
        addNewAddress,
    }
}