<template>
    <div>
        <ContractDialog
            :displayPosition="displayPosition"
            :position="position"
            @closePosition="closePosition"
        />
        <h4 class="contracts-title">Список договоров на услугу Интернет</h4>
        <div class="p-grid  p-mb-2">
            <div class="p-col">
                <Button
                    @click="openPosition('top')"
                    label="+ Подключить новый адрес"
                />
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col" v-for="item in info" :key="item.id">
                <ContractCard :item="item"/>
            </div>
        </div>
        <h4 class="contracts-title">Приостановленные договора которые можно Активировать на сутки</h4>
        <div class="p-grid p-mt-5" v-if="suspendedInfo.length !== 0">
            <div class="p-col" v-for="item in suspendedInfo" :key="item.id">
                <SuspendedContract/>
            </div>
        </div>
        <div class="p-grid p-mt-5" v-else>
            <h5 class="suspend">
                Приостановленные договора отсутствуют!
            </h5>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import ContractCard from '@/components/contractCard/ContractCard'
import SuspendedContract from "@/components/suspendedContract/SuspendedContract";
import ContractDialog from "@/components/contractDialog/ContractDialog";
import {useContracts} from "./useContracts";

export default {
    setup() {
        return {
            ...useContracts()
        }
    },
    components: {
        ContractCard,
        ContractDialog,
        SuspendedContract,
        Button,
    }
}
</script>

<style scoped>
.contracts-title {
    color: var(--yellow-500);
}

.suspend {
    color: #ffffff;
    text-align: center;
    width: 100%;
}
</style>