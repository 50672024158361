<template>
    <SupportDialog
        :displayPosition="displayPosition"
        :position="position"
        @closePosition="closePosition"
    />
    <div class="p-grid p-mt-3">
        <div class="p-col">
            <h3 class="header-support">Техническая поддержка</h3>
            <div class="p-grid  p-mb-2">
                <div class="p-col">
                    <Button
                        @click="openPosition('top')"
                        label="+ Создать новое обращение"
                    />
                </div>
            </div>
            <SupportAccordion/>
        </div>
    </div>
</template>

<script>
import SupportAccordion from "@/components/accordions/support-accordion/SupportAccordion";
import SupportDialog from "@/components/supportDialog/SupportDialog";
import Button from 'primevue/button';
import {ref} from "vue";

export default {
    name: "Support",
    setup() {
        const position = ref('center');
        const displayPosition = ref(false);

        const closePosition = (value) => {
            displayPosition.value = value;
        };

        const openPosition = (pos) => {
            position.value = pos;
            displayPosition.value = true;
        };

        return {
            position,
            displayPosition,
            closePosition,
            openPosition,
        }
    },
    components: {
        SupportAccordion,
        Button,
        SupportDialog
    }
}
</script>

<style scoped>
.header-support {
    color: #ffffff;
}
</style>