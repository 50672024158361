<template>
    <div>
        <div class="card">
            <DataTable :value="history"
                       :paginator="true"
                       :rows="20"
                       data-key="id"
                       v-model:filters="filters"
                       filterDisplay="row"
                       :globalFilterFields="['actionCategory']"
                       ref="dt"
                       :totalRecords="totalRecords"
                       :loading="loading"
                       @page="onPage($event)"
                       paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                       responsiveLayout="scroll"
            >
                <Column field="actionCategory" header="Категория действий" :showFilterMenu="false"
                        style="min-width:12rem">
                    <template #body="{data}">
                        {{ data.actionCategory }}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="actionCategories"
                                  placeholder="Все" class="p-column-filter" :showClear="true">
                            <template #value="slotProps">
                                <span v-if="slotProps.value">{{ slotProps.value }}</span>
                                <span v-else>{{ slotProps.placeholder }}</span>
                            </template>
                            <template #option="slotProps">
                                <span>{{ slotProps.option }}</span>
                            </template>
                        </Dropdown>
                    </template>
                </Column>
                <Column field="description" header="Описание"></Column>
                <Column field="date" header="Дата"></Column>
                <Column field="essence" header="Сущьность"></Column>
                <template #paginatorLeft>
                </template>
                <template #paginatorRight>
                </template>
            </DataTable>
        </div>
    </div>
</template>

<script>
import {useActionHistoryTable} from "./useActionHistoryTable";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from "primevue/dropdown";

export default {
    name: "ActionHistory",

    setup() {
        return {
            ...useActionHistoryTable()
        }
    },
    components: {
        DataTable,
        Column,
        Dropdown
    }
}
</script>

<style scoped>

</style>