<template>
    <Panel class="contract contract-card">
        <template #header>
            <h4 class="header-contract-card">{{ item.name }}</h4>
        </template>
        <template #icons>
            <button class="p-panel-header-icon p-link p-mr-2" @click="toggle">
                <span class="pi pi-cog"></span>
            </button>
            <Menu id="config_menu" ref="menu" :model="items" :popup="true"/>
        </template>
        <div class="content">
            <Divider align="center">
                <div class="p-d-inline-flex p-ai-center">
                    <b>Информация о договоре</b>
                </div>
            </Divider>
            <div class="p-grid" v-for="field in item.fieldNames" :key="field.id">
                <div class="p-col-5">
                    <div class="content-info-key">{{ field.fieldName }}:</div>
                </div>
                <div class="p-col">
                    <strong class="content-info-card">{{ field.fieldKey }}</strong>
                </div>
            </div>
        </div>
    </Panel>
</template>

<script>
import Panel from 'primevue/panel';
import Menu from 'primevue/menu';
import Divider from 'primevue/divider';
import {useContractCard} from "./useContractCard";

export default {
    props: ['item'],
    setup() {

        return {
            ...useContractCard()
        }
    },
    components: {
        Panel,
        Menu,
        Divider,
    }
}
</script>

<style scoped>
.contract-card {
    width: 480px;
}

.header-contract-card {
    color: #ffffff;
}

.content-info-card {
    color: #686dce;
}

.content {
    margin-top: -35px;
}

.content-info-key {
    font-weight: 600;
    color: #ffffff;
}
</style>