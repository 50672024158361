import {ref} from "vue";
import getData from "@/api/getData/getData";
import {widgetsFinance} from "@/api/url/widgetFinanceUrls";

export function useWidgetsFinance() {
    const items = ref([])
    const isLoading = ref(true)

    getData(widgetsFinance).then((response) => {
        if (response.request.readyState === 4) {
            items.value = response.data
            isLoading.value = false
        }
    })

    return {
        isLoading,
        items
    }
}