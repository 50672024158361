<template>
    <div v-if="isLoading" class="p-col">
        <h4 class="contracts-title">Быстрый доступ к договорам</h4>
        <Skeleton height="319px" borderRadius="16px"></Skeleton>
    </div>
    <div v-else class="card p-col">
        <h4 class="contracts-title">Быстрый доступ к договорам</h4>
        <div v-if="items.length <= 3" class="p-grid contracts-container">
            <div class="p-lg-4 p-md-6 p-sm-12 p-col-12" v-for="item in items" :key="item.id">
                <ContractItem :contract="item"/>
            </div>
        </div>
        <Carousel v-else :value="items" :numVisible="3" :numScroll="3" :responsiveOptions="contractsCarousel">
            <template #item="slotProps">
                <contract-item-slider
                    :slotProps="slotProps"
                >
                </contract-item-slider>
            </template>
        </Carousel>
    </div>
</template>

<script>
import {ref} from 'vue'
import {caruselOptions, contractCarouselOptions} from './caruselOptions'
import {useContracts} from './useContracts'
import Carousel from 'primevue/carousel';
import ContractItem from "./ContractItem";
import ContractItemSlider from "./ContractItemSlider";
import Skeleton from "primevue/skeleton";

export default {

    setup() {

        const contractsCarousel = ref(contractCarouselOptions)

        return {
            ...caruselOptions(),
            ...useContracts(),
            contractsCarousel,
        }
    },
    components: {
        ContractItem,
        ContractItemSlider,
        Carousel,
        Skeleton
    }
}
</script>
<style scoped>
.contracts-title {
    color: var(--yellow-500);
}

.contracts-container {
    padding-left: 35px;
    padding-right: 35px;
}

@media (max-width: 1200px) {
    .contracts-container {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 992px) {
    .contracts-title {
        margin-top: 0;
    }
}

@media (max-width: 500px) {
    .contracts-title {
        font-size: 1.1rem;
    }
}
</style>