import getSuspendedContracts from "@/api/contracts/getSuspendedContracts";
import getActivatedContract from "@/api/contracts/getActivatedContract";

export default {
    namespaced: true,
    state: () => {
        return {
            data: null,
            activate: false,
            activatedDate: localStorage.getItem("suspendedDate") || null
        }
    },
    mutations: {
        setData(state, newData) {
            return state.data = newData;
        },

        setActivate(state) {
            let valueActivated = getSuspendedContracts.activate;
            return state.activate = valueActivated;
        },

        setActivatedDate(state, newActivatedDate) {
            return state.activatedDate = newActivatedDate;
        }
    },
    actions: {
        async susContracts({commit}) {
            const {data} = await getSuspendedContracts()
            commit('setData', data[0])
            commit('setActivatedDate', data[0].activatedDate)
            localStorage.setItem('suspendedDate', data[0].activatedDate)
        },
        async activatedContract({commit}) {
            const {data} = await getActivatedContract()
            commit('setData', data[0])
            commit('setActivatedDate', data[0].activatedDate)
            localStorage.setItem('suspendedDate', data[0].activatedDate)
        }

    },
    getters: {
        getActivatedDate(state) {
            return state.activatedDate
        },
        getSuspendedData(state) {
            return state.data
        }
    }
}