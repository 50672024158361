<template>
    <div class="p-col-12">
        <div class="p-grid header">
            <div class="p-grid p-xl-1 p-md-1 p-sm-2 p-xs-2 p-align-center p-justify-end logo-header">
                <img src="@/assets/img/logo-not-fon.png" alt="logo" class="img-logo-header">
            </div>
            <div class="p-grid p-xl-11 p-md-11 p-sm-10 p-xs-10 text-header p-align-center">
                <p>Личный кабинет абонента ГК "МАРТ"</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style scoped>
.header {
    background-color: #1f2d40;
    color: white;
    margin-top: 0;
}

.logo-header {
    margin: 0;
}

.img-logo-header {
    width: 50%
}

.text-header > p {
    margin: 0 0 0 20px;
    font-size: 16px;
}

@media (max-width: 992px) {
    .img-logo-header {
        width: 70%;
    }
}

@media (max-width: 768px) {
    .img-logo-header {
        width: 90%;
    }
}

@media (max-width: 575px) {
    .img-logo-header {
        width: 15%;
    }

    .logo-header {
        justify-content: center;
    }

    .header {
        justify-content: center;
    }

    .text-header > p {
        margin: 0 0 0 0;
        font-size: 14px;
    }
}
</style>