<template>
    <Chart type="doughnut" :data="chartData" :options="lightOptions"/>

</template>

<script>
import Chart from 'primevue/chart';
import {useDiagram} from "./useDiagram";

export default {
    components: {
        Chart
    },
    setup() {
        return {
            ...useDiagram()
        }
    }
}
</script>

<style scoped>

</style>