export const citiesConstant = [
    {name: 'Великие Луки', code: 'Великие Луки'},
    {name: 'Новосокольники', code: 'Новосокольники'},
    {name: 'Себеж', code: 'Себеж'},
    {name: 'Невель', code: 'Невель'},
    {name: 'Нагорный', code: 'Нагорный'},
    {name: 'Дорожный', code: 'Дорожный'},
    {name: 'Переслегино', code: 'Переслегино'},
    {name: 'Жестки', code: 'Жестки'},
    {name: 'Золотково', code: 'Золотково'},
]
export const streetTypesConstant = [
    {name: 'улица', code: 'улица'},
    {name: 'переулок', code: 'переулок'},
    {name: 'проспект', code: 'проспект'},
    {name: 'проезд', code: 'проезд'},
    {name: 'площадь', code: 'площадь'},
    {name: 'набережная', code: 'набережная'},
]