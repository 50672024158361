<template>
    <div class="p-grid p-mt-3">
        <div class="p-col-12">
            <h3 class="finance-header">Финансы. Оплата. История платежей.</h3>
            <div class="p-grid p-md-12">
                <div class="p-xl-8 p-lg-8 p-md-12 p-sm-12">
                    <PaymentMethod/>
                </div>
                <div class="p-xl-4 p-lg-4 p-md-12 p-sm-12">
                    <WidgetsFinancePanel/>
                </div>
            </div>
            <div class="p-grid p-md-12">
                <div class="p-col-12">
                    <PaymentHistory/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import WidgetsFinancePanel from "@/components/dashboard/widgets-finance/WidgetsFinancePanel";
import PaymentMethod from "@/components/paymentMethod/PaymentMethod";
import PaymentHistory from "@/components/paymentHistory/PaymentHistory";

export default {
    components: {
        WidgetsFinancePanel,
        PaymentMethod,
        PaymentHistory,
    }
}
</script>

<style scoped>
.finance-header {
    color: #ffffff;
}

@media (max-width: 400px) {
    .finance-header {
        font-size: 1.3rem;
        margin-bottom: 5px;
        margin-top: 5px;
    }
}
</style>