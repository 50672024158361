<template>
    <Card class="card-payment">
        <template #title>
            <h4 class="finance-card-header">Оплата онлайн и без комиссии</h4>
        </template>
        <template #content>
            <PaymentMethodForm/>
            <Button label="Другие способы оплаты..." class="p-button-link p-mt-3"/>
        </template>
    </Card>
</template>

<script>
import Card from 'primevue/card';
import Button from 'primevue/button';
import PaymentMethodForm from "./PaymentMethodForm";

export default {
    components: {
        Card,
        Button,
        PaymentMethodForm,
    }
}
</script>

<style scoped>
.finance-card-header {
    color: #686dce;
}

.card-payment {
    width: 100%;
    margin-bottom: 2em;
    padding-left: 2em;
}

@media (max-width: 450px) {
    .card-payment {
        padding: 0.5rem 0.5rem !important;
    }

    .finance-card-header {
        margin-bottom: 5px;
        margin-top: 5px;
    }
}
</style>