<template>
    <div class="p-grid">
        <div class="p-col-12">
            <Fieldset legend="Контактные данные" class="p-mt-3">
                <div class="p-grid">
                    <div class="p-col">
                        <p>ФИО: {{ fullName }}</p>
                    </div>
                </div>
                <Divider></Divider>
                <div class="p-grid">
                    <div class="p-col">
                        <p>Email: {{ email }}</p>
                    </div>
                    <div class="p-col-2">
                        <Button
                            icon="pi pi-pencil"
                            class="p-button-rounded p-button-outlined"
                            v-tooltip.top="'Редактировать email'"
                            @click="openEmailModal"
                        />
                    </div>
                </div>
                <Divider></Divider>
                <div class="p-grid">
                    <div class="p-col">
                        <p>Мобильный телефон: +7{{ phone }}</p>
                    </div>
                    <div class="p-col-2">
                        <Button
                            icon="pi pi-pencil"
                            class="p-button-rounded p-button-outlined"
                            v-tooltip.top="'Редактировать телефон'"
                            @click="openPhoneModal"
                        />
                    </div>
                </div>
            </Fieldset>
        </div>
    </div>

    <Dialog
        header="Сменить email"
        v-model:visible="displayEmailModal"
        :modal="true"
        :dismissableMask="true"
    >
        <div class="p-fluid p-formgrid p-grid p-pt-1">
            <div class="p-field p-col-12 p-xl-8 p-lg-8 p-md-8 p-sm-12">
        <span class="p-input-icon-left">
          <i class="pi pi-envelope"/>
          <InputText
              id="new-email"
              type="text"
              v-model="newEmail"
              placeholder="Введите новый email"
              :class="{'p-invalid':newEmailErrorMessage}"
          />
        </span>
                <small v-if="newEmailErrorMessage" class="p-error">{{ newEmailErrorMessage }}</small>
            </div>
            <div class="p-field p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                <Button label="Сменить" icon="pi pi-pencil" class="p-button-text p-text-left" @click="sendEmail()"/>
            </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-xl-8 p-lg-8 p-md-8 p-sm-12">
        <span class="p-input-icon-left">
          <i class="pi pi-key"/>
          <InputText
              id="email-code"
              type="text"
              v-model="emailCode"
              placeholder="Введите код подтверждения"
              :disabled="!emailSent"/>
        </span>
            </div>
            <div class="p-field p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                <Button label="Подтвердить" icon="pi pi-check" class="p-button-text p-text-left"
                        @click="sendEmailCode(), resetForm()"
                        :disabled="!emailSent"/>
            </div>
        </div>
        <CustomMessage
            v-if="emailSent"
            type="info"
            header="Внимание"
            text="На ваш почтовый адрес было отправленно письмо с кодом подтверждения"
        />
    </Dialog>

    <Dialog
        header="Сменить номер телефона"
        v-model:visible="displayPhoneModal"
        :modal="true"
        :dismissableMask="true"
    >
        <div class="p-fluid p-formgrid p-grid p-pt-1">
            <div class="p-field p-col-12 p-xl-8 p-lg-8 p-md-8 p-sm-12">
        <span class="p-input-icon-left">
          <i class="pi pi-phone"/>
          <InputText
              type="text"
              v-model="newPhone"
              placeholder="Введите новый номер телефона"
              :class="{'p-invalid':newPhoneErrorMessage}"
          />
        </span>
                <small v-if="newPhoneErrorMessage" class="p-error">{{ newPhoneErrorMessage }}</small>
            </div>
            <div class="p-field p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                <Button label="Сменить" icon="pi pi-pencil" class="p-button-text p-text-left" @click="sendPhone()"/>
            </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-xl-8 p-lg-8 p-md-8 p-sm-12">
        <span class="p-input-icon-left">
          <i class="pi pi-key"/>
          <InputText type="text" v-model="phoneCode" placeholder="Введите код подтверждения" :disabled="!phoneSent"/>
        </span>
            </div>
            <div class="p-field p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                <Button label="Подтвердить" icon="pi pi-check" class="p-button-text p-text-left"
                        @click="sendPhoneCode(), resetForm()"
                        :disabled="!phoneSent"/>
            </div>
        </div>
        <CustomMessage
            v-if="phoneSent"
            type="info"
            header="Внимание"
            text="На ваш номер телефона было отправленно SMS с кодом подтверждения"
        />
    </Dialog>
</template>

<script>
import Fieldset from 'primevue/fieldset'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider'
import InputText from 'primevue/inputtext';
import CustomMessage from "@/components/ui/CustomMessage";
import {useChangeEmail} from "@/components/dashboard/profile/tabs/myDataTab/useChangeEmail";
import {useChangePhone} from "@/components/dashboard/profile/tabs/myDataTab/useChangePhone";

export default {
    props: {
        fullName: String,
        email: String,
        phone: String,
    },

    setup() {

        return {
            ...useChangeEmail(),
            ...useChangePhone()
        }
    },

    components: {
        Fieldset,
        Button,
        Dialog,
        Divider,
        InputText,
        CustomMessage
    }
}
</script>