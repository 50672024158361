<template>
    <Panel class="contract contract-card">
        <template #header>
            <h4 class="header-contract-card">{{ susData?.name }}</h4>
        </template>
        <div class="content">
            <Divider align="center">
                <div class="p-d-inline-flex p-ai-center">
                    <b>Информация о договоре</b>
                </div>
            </Divider>
            <div class="p-grid" v-for="field in susData?.fieldNames" :key="field.id">
                <div class="p-col-5">
                    <div class="content-info-key">{{ field.fieldName }}:</div>
                </div>
                <div class="p-col">
                    <strong
                        :class="[field.fieldKey.substring(0, 13) === 'Приостановлен' ? 'diasbledClass' : 'content-info-card']">{{
                            field.fieldKey
                        }}</strong>
                </div>
            </div>
            <Divider align="center">
                <div class="p-d-inline-flex p-ai-center" v-tooltip.right="textTooltip">
                    <b>Условия предоставления услуги</b>
                    <i class="pi pi-question-circle p-ml-2 question-suspend"></i>
                </div>
            </Divider>
            <div class="p-grid">
                <div class="p-col-5">
                    <div class="content-info-key">Стоимость активации:</div>
                </div>
                <div class="p-col">
                    <strong class="content-info-card">{{ susData?.payment }} руб.</strong>
                </div>
            </div>
            <div v-if="susData?.activated">
                <p class="p-text-center">
                    <Timer/>
                </p>
            </div>
            <div v-else>
                <div v-if="susData?.money < susData?.payment">
                    <h6 class="no-money">На вашем лицевом счете недостаточно средств для активации услуги! Пожалуйста
                        пополните
                        счет.</h6>
                </div>
                <div v-else>
                    <div class="p-grid p-mt-3">
                        <div class="p-col">
                            <Checkbox id="binary" v-model="confirmChecked" :binary="true"/>
                            <label for="binary"> *Настоящим соглашением вы подтверждаете,
                                что ознакомлены с условиями предоставления услуги
                                «Интернет на сутки».
                            </label>
                        </div>
                    </div>
                    <div class="p-grid p-mt-3">
                        <div class="p-col">
                            <Button label="Активировать" @click="activated" :disabled="!confirmChecked"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Panel>
</template>

<script>
import Panel from 'primevue/panel';
import Checkbox from 'primevue/checkbox';
import Divider from 'primevue/divider';
import Button from 'primevue/button';
import {useSuspendedContract} from "./useSuspendedContract";
import Timer from '@/components/ui/Timer'

export default {
    setup() {
        return {
            ...useSuspendedContract(),
        }
    },
    components: {
        Panel,
        Divider,
        Checkbox,
        Button,
        Timer,
    }
}
</script>

<style scoped>
.contract-card {
    width: 480px;
}

.header-contract-card {
    color: #ffffff;
}

.content-info-card {
    color: #686dce;
}

.content {
    margin-top: -35px;
}

.content-info-key {
    font-weight: 600;
    color: #ffffff;
}

.diasbledClass {
    color: #ff0000;
}

.question-suspend {
    font-size: 20px;
    color: #ffcd2e;
    cursor: pointer;
}

.no-money {
    color: #ffcd2e;
}
</style>