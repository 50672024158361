import {ref, computed} from "vue";
import {useStore} from "vuex";

export function useSuspendedContract() {
    const store = useStore()
    store.dispatch('suspendedContracts/susContracts')

    const confirmChecked = ref(false);

    const textTooltip = `- Услуга доступна только во время приостановки договора.
- Стоимость услуги составляет 5% от стоимости тарифного плана.
- Скорость получения/передачи данных до 100 Мбит/сек.
- Подключение услуги возможно только при положительном балансе, который равен стоимости услуги (денежные средства снимаются в момент подключения).
- Договор должен быть приостановлен по заявлению от абонента.
- На время действия услуги белый IP-адрес не предоставляется.
- Для активации услуги необходимо перезагрузить оборудование (wi-fi роутер, компьютер и др.).
`

    const toggle = (event) => {
        menu.value.toggle(event);
    };
    const menu = ref(null);

    const activated = () => {
        store.dispatch('suspendedContracts/activatedContract')
    }


    return {
        toggle,
        menu,
        textTooltip,
        confirmChecked,
        susData: computed(() => {
            return store.getters['suspendedContracts/getSuspendedData']
        }),
        activated,
    }
}