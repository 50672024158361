import {instanceAxios} from "@/axios/axios";
import cookie from "js-cookie";

export default function confirmEmail(code) {
    return new Promise((resolve, reject) => {
        instanceAxios
            .post(
                'confirm-email',
                {
                    params: {
                        userToken: cookie.get('token')
                    },
                    confirmationCode: code,
                }
            )
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}