import {ref, onMounted} from 'vue';
import getData from "@/api/getData/getData";
import {technicalInfo} from "@/api/url/technicalInfoUrls";

export function useTechnicalInfo() {
    const products = ref([]);
    const isLoading = ref(true)

    onMounted(() => {
        getData(technicalInfo).then((response) => {
            if (response.request.readyState === 4) {
                products.value = response.data
                isLoading.value = false
            }
        })
    })
    return {
        products,
        isLoading
    }
}