<template>
    <span :class="[type === 'default' ? '' : 'activated-time']">Услуга будет отключена через: <strong>{{
            time
        }}</strong></span>
</template>

<script>
import {onUnmounted, ref} from 'vue'
import moment from "moment"

export default {
    props: {
        type: {
            type: String,
            required: false
        }
    },
    setup() {
        let dateDisconnections = moment.unix(localStorage.getItem("suspendedDate")).utc();
        let interval = null;
        const time = ref()
        interval = setInterval(() => {
            let now = moment(new Date());
            let duration = moment.duration(dateDisconnections.diff(now));
            time.value = `${duration.hours()}ч ${duration.minutes()}м ${duration.seconds()}с`
        }, 1000)
        onUnmounted(() => {
            clearInterval(interval)
        })

        return {
            time
        }
    }
}
</script>

<style scoped>
.activated-time {
    color: #ffcd2e;
    font-size: 1.4rem;
}
</style>