<template>
    <div class="p-grid">
        <div class="p-col-12 p-text-center p-mt-2">
            <strong class="info-agreement">
                Нажимая на кнопку "Войти", Вы принимаете Согласие в рамках политики компании в отношении
                обработки персональных данных
            </strong>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style scoped>
@media (max-width: 560px) {
    .info-agreement {
        font-size: 0.8rem;
    }
}
</style>