import {useField} from "vee-validate";
import {useForm} from "vee-validate";
import * as yup from "yup";
import postPaymentMethod from "@/api/paymentMethod/postPaymentMethod";

export function usePaymentMethod() {

    const {handleSubmit} = useForm()

    const {
        value: paymentMethod,
        errorMessage: errorPaymentMethod,
        handleBlur: blurPaymentMethod
    } = useField('paymentMethod',
        yup
            .string()
            .trim()
            .required()
    )

    const {value: paymentCount, errorMessage: errorPaymentCount, handleBlur: blurPaymentCount} = useField('count',
        yup
            .string()
            .trim()
            .required()
    )

    const sendPayment = handleSubmit(async (values) => {
        await postPaymentMethod(values)
            .then((response) => {
                if (response.request.readyState === 4) {
                    console.log('success')
                }
            })
    })

    return {
        paymentMethod,
        errorPaymentMethod,
        blurPaymentMethod,
        paymentCount,
        errorPaymentCount,
        blurPaymentCount,
        sendPayment,
    }
}