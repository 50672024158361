import {ref} from "vue";

export function useContractCard() {
    const items = ref([
        {
            items: [
                {
                    label: 'Активировать обещанный платеж',
                    icon: 'pi pi-check-circle',
                },
                {
                    label: 'Приостановить',
                    icon: 'pi pi-power-off',
                },
                {
                    label: 'Сменить тарифный план',
                    icon: 'pi pi-book',
                },
                {
                    label: 'Сменить MAC-адрес',
                    icon: 'pi pi-sitemap',
                },
            ]
        },
    ]);

    const toggle = (event) => {
        menu.value.toggle(event);
    };
    const menu = ref(null);

    return {
        toggle,
        items,
        menu,
    }
}