<template>
    <Toast/>
    <FaqModal
        :isOpenModal="isOpenModal"
        :onUpload="onUpload"
    />
    <div class="p-grid p-mt-3">
        <div class="p-col">
            <h3 class="header-faq">Ответы на вопросы, инструкции</h3>
            <FaqAccordion/>
            <div class="p-mt-4">
                <CustomMessage
                    header="Задайте нам вопрос"
                    text="Вы можете отправить нам свой вопрос и мы ответим на него в ближайшее время"
                    :button="{text:'Задать вопрос', type:'success'}"
                    @handleClick="showNewAppeal"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {ref, computed} from 'vue'
import {useToast} from "primevue/usetoast";
import FaqAccordion from "@/components/accordions/faq-accordion/FaqAccordion";
import CustomMessage from "@/components/ui/CustomMessage";
import FaqModal from "@/components/accordions/faq-accordion/FaqModal";
import Toast from 'primevue/toast';

export default {
    setup() {

        const isOpenModal = ref(false)

        const closeModal = () => {
            isOpenModal.value = false;
        }

        const showNewAppeal = () => {
            isOpenModal.value = true;
        }

        const toast = useToast();
        const onUpload = () => {
            toast.add({severity: 'success', summary: 'Ваше обращение отправлено', life: 3000});
        }

        return {
            showNewAppeal,
            onUpload,
            closeModal,
            isOpenModal: computed(() => isOpenModal)
        }
    },
    components: {
        FaqAccordion,
        CustomMessage,
        FaqModal,
        Toast
    }
}
</script>

<style scoped>
.header-faq {
    color: #ffffff;
}
</style>