import MainLayout from "../layout/MainLayout";
import Profile from "../view/Profile";
import Home from "../view/Home";
import FAQ from "../view/FAQ";
import Finance from "../view/Finance";
import Contracts from "../view/Contracts";
import Support from "@/view/Support";
import Antivirus from "@/view/antivirus/Antivirus";
import AntivirusMobile from "@/view/antivirus/AntivirusMobile";
import AntivirusPremium from "@/view/antivirus/AntivirusPremium";

export const mainRoutes = [
    {
        path: '/dashboard',
        alias: '',
        name: 'dashboard',
        component: MainLayout,
        meta: {
            layout: 'main',
            isAuth: true
        },
        children: [
            {
                path: 'home',
                alias: '',
                name: 'home',
                component: Home,
            },
            {
                path: 'profile',
                name: 'profile',
                component: Profile
            },
            {
                path: 'support',
                name: 'support',
                component: Support
            },
            {
                path: 'FAQ',
                name: 'FAQ',
                component: FAQ
            },
            {
                path: 'Finance',
                name: 'Finance',
                component: Finance
            },
            {
                path: 'Contracts',
                name: 'Contracts',
                component: Contracts
            },
            {
                path: 'Antivirus',
                name: 'Antivirus',
                component: Antivirus
            },
            {
                path: 'Mobile',
                name: 'AntivirusMobile',
                component: AntivirusMobile
            },
            {
                path: 'Premium',
                name: 'AntivirusPremium',
                component: AntivirusPremium
            }
        ]
    },
]