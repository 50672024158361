<template>
    <div class="p-p-3 p-grid block-home">
        <div class="p-col">
            <div class="p-grid-column">
                <div class="p-col">
                    <Greetings username="Артем"/>
                </div>
                <div class="p-col">
                    <CustomMessage
                        type="error"
                        header="Внимание!"
                        text=" До отключения услуг связи осталось 4 дня!"
                        :button="{
                text:'Пополнить баланс',
                type: 'danger'
            }"
                        closable
                    />
                </div>
                <div class="p-col">
                    <CustomMessage
                        type="success"
                        header="Добро пожаловать в обновленный личный кабинет!"
                        text="Совсем скоро мы добавим в обновленный личный кабинет
            еще больше нового функционала. А пока предлагаем познакомиться
            с уже имеющимися разделами кабинета и их возможностями."
                        :button="{
                text:'Познакомиться',
                type: 'success'
            }"
                        closable
                    />
                </div>
                <div class="p-col">
                    <CustomMessage
                        type="warn"
                        header="Настройка Автоплатежа"
                        text="Подключите Автоплатеж и баланс вашего лицевого счета
             будет пополнятся автоматически каждый месяц!"
                        :button="{
                text:'Привязать карту',
                type: 'warning'
            }"
                        closable
                    />
                </div>
                <div class="p-col" v-if="showTimeMessage">
                    <CustomMessage
                        type="info"
                        header="Внимание!"
                        text='Для приостановленного договора №079652 активирована услуга "Интернет на сутки".'
                        closable
                    >
                        <template v-slot:timer>
                            <Timer type="default"/>
                        </template>
                    </CustomMessage>
                </div>
            </div>
        </div>
    </div>
    <div class="p-p-3 p-grid block-home">
        <div class="p-col">
            <div class="p-grid">
                <WidgetsPanel/>
            </div>
        </div>
    </div>
    <div class="p-p-3 p-grid block-home">
        <div class="p-col">
            <div class="p-grid">
                <Contracts/>
            </div>
        </div>
    </div>
    <div class="p-p-3 p-grid block-home">
        <div class="p-col">
            <div class="p-grid">
                <Offers/>
            </div>
        </div>
    </div>

</template>

<script>
import Greetings from "@/components/dashboard/Greeting";
import CustomMessage from "@/components/ui/CustomMessage";
import WidgetsPanel from "@/components/dashboard/widgets/WidgetsPanel";
import Contracts from "@/components/contracts/Contracts";
import Offers from "@/components/offers/Offers";
import Timer from "@/components/ui/Timer"
import {onMounted, ref} from "vue";

export default {
    setup() {
        let user = null;
        let showTimeMessage = ref(false)
        onMounted(() => {
            user = JSON.parse(localStorage.getItem('user'));
            if (localStorage.getItem('suspendedDate') !== 'null' && localStorage.getItem('suspendedDate') !== null) {
                showTimeMessage.value = true
            } else {
                showTimeMessage.value = false
            }
        })

        return {
            user,
            showTimeMessage
        }
    },

    components: {
        Greetings,
        CustomMessage,
        Contracts,
        Offers,
        WidgetsPanel,
        Timer
    }
}
</script>
<style scoped>
@media (max-width: 500px) {
    .block-home {
        padding: 0 !important;
    }
}
</style>