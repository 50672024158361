<template>
    <TabView class="tabview-custom">
        <TabPanel v-for="(tab, index) in tabsConfig" :key="tab.name + index">
            <template #header>
                <i :class='tab.icon' class="icon-tab"></i>
                <span class="tab-title">{{ tab.name }}</span>
            </template>
            <component :is="tab.component"></component>
        </TabPanel>
    </TabView>
</template>

<script>
import {tabsConfig} from './tabsConfig'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

export default {

    setup() {

        return {
            tabsConfig
        }

    },
    components: {
        TabView,
        TabPanel,
    }
}
</script>

<style scoped>
i.pi {
    font-weight: 700;
}

.icon-tab {
    margin-right: 0.75rem;
}

.tab-title {
    text-transform: uppercase;
    font-weight: 700;
}

@media (max-width: 545px) {
    .tab-title {
        display: none;
    }

    .icon-tab {
        margin-right: 0 !important;
    }
}
</style>