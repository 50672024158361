import {ref} from "vue";
import getData from "@/api/getData/getData";
import {contracts} from "@/api/url/contractsUrls";

export function useContracts() {
    const items = ref([])
    const isLoading = ref(true)

    getData(contracts).then((response) => {
        if (response.request.readyState === 4) {
            items.value = response.data
            isLoading.value = false
        }
    })

    return {
        isLoading,
        items
    }
}