<template>
    <div class="p-mt-1 p-mb-3">
        <DataTable :value="products" responsiveLayout="scroll">
            <Column v-if="isLoading"  field="code" header="Описание сервера">
                <template #body>
                    <Skeleton></Skeleton>
                </template>
            </Column>
            <Column v-else  field="code" header="Описание сервера"></Column>
            <Column v-if="isLoading" field="name" header="Адрес сервера">
                <template #body>
                    <Skeleton></Skeleton>
                </template>
            </Column>
            <Column v-else field="name" header="Адрес сервера"></Column>
        </DataTable>
    </div>
</template>

<script>
    import {useTechnicalInfo} from './useTechnicalInfo'
    import Skeleton from 'primevue/skeleton';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';

    export default {
        setup() {
            return{
                ...useTechnicalInfo()
            }
        },
        components: {
            DataTable,
            Column,
            Skeleton,
        }
    }
</script>