<template>
    <div class="p-grid">
        <div class="p-col-12">
            <h1 class="p-text-center p-mb-6">Dr.WEB ПРЕМИУМ</h1>
            <h4 class="p-text-center">Лучший тариф Dr.Web для активных пользователей Интернета</h4>
            <div class="p-grid p-lg-10 p-md-12 p-lg-offset-2 p-md-offset-0 p-jc-center">
                <div class="p-xl-3 p-lg-4 p-md-4 p-sm-12 p-text-center">
                    <img src="@/assets/img/antivirus/premium.png" alt="mobile">
                </div>
                <div class="p-xl-6 p-lg-8 p-md-8 p-mt-6 p-sm-12">
                    <ul>
                        <li class="p-mb-2"><i class="pi pi-check attention p-mr-2"></i> Максимально возможная свобода в
                            сети без
                            ограничений в безопасности
                        </li>
                        <li class="p-mb-2"><i class="pi pi-check attention p-mr-2"></i> Максимальная защита Dr.Web для
                            Windows,
                            MacOS,
                            Linux
                        </li>
                        <li class="attention p-mb-2"><i class="pi pi-check p-mr-2"></i> <strong>
                            1 подписка = защита 1 ПК + защита 1 Android-устройства в подарок
                        </strong>
                        </li>
                    </ul>
                    <p class="dr-web-price">
                        <strong>
                            <span class="font-price">99</span> руб./месяц
                        </strong>
                    </p>
                    <div class="connect">
                        <Button @click="openDrWebPrem">Подключить <br> DR.WEB ПРЕМИУМ</Button>
                        <Dialog
                            header="Подключение услуги"
                            v-model:visible="displayDrWebPrem"
                            :modal="true"
                            :dismissableMask="true"
                            :style="{width: '700px'}"
                        >
                            <div class="p-col-12 message-connect p-text-center p-mt-3 p-mb-4">
                                <h4>
                                    Вы можете оставить заявку, нажав кнопку "Подключить"!
                                </h4>
                            </div>
                            <h4>Активация</h4>
                            <p>Подписка производится на любой срок. Услуга начинает действовать немедленно после
                                активации подписки и
                                установки ПО Dr.Web. На лицевом счете абонента должна находиться сумма, необходимая для
                                оплаты услуги за
                                остаток текущего месяца. <a href="/finance" class="mark-link" target="_blank">Способы
                                    оплаты.</a></p>
                            <h4>Продление</h4>
                            <p>Заботиться о продлении не нужно — подписка продлевается автоматически при своевременной
                                оплате
                                услуг.</p>
                            <h4>Отказ от услуги</h4>
                            <p>
                                В любой момент можно отказаться от пользования услугой. Она останется активной только до
                                конца
                                оплаченного периода. При возобновлении клиентом подписки на услугу ссылка на скачивание
                                установочного
                                файла вновь становится доступна.
                            </p>
                            <Checkbox id="binary" v-model="confirmChecked" :binary="true"/>
                            <label for="binary"> Я принимаю условия <a href="pdf/license_avdesk.pdf" class="mark-link"
                                                                       target="_blank">лицензионного договора об
                                использовании
                                програмного обеспечения ООО "Доктор Веб"</a>
                            </label>
                            <template #footer>
                                <Button label="Подключить" icon="pi pi-check" @click="closeDrWebPrem"
                                        :disabled="!confirmChecked"/>
                            </template>
                        </Dialog>
                    </div>
                    <ul class="list-instructions">
                        <li class="attention p-mb-2"><i class="pi pi-check p-mr-2"></i> <a
                            href="https://www.drweb.ru/saas/support/video/"
                            target="_blank" class="attention-border">
                            Видео (подключение услуги и установка программ Dr.Web)</a></li>
                        <li class="attention p-mb-2"><i class="pi pi-check p-mr-2"></i> <a
                            href="https://www.drweb.ru/saas/support/install/"
                            target="_blank" class="attention-border">
                            Инструкция по установке</a></li>
                        <li class="attention p-mb-2"><i class="pi pi-check p-mr-2"></i> <a
                            href="https://download.drweb.ru/doc/"
                            target="_blank" class="attention-border">
                            Руководство пользователя (PDF)</a></li>
                    </ul>
                </div>
            </div>
            <div class="p-col-12 p-mb-3">
                <h3>Компоненты защиты</h3>
                <table class="table">
                    <thead>
                    <tr>
                        <th class="p-text-left">Компоненты</th>
                        <th class="tableHeaderWidth">Windows</th>
                        <th class="tableHeaderWidth p-text-center">MacOS</th>
                        <th class="tableHeaderWidth">Linux</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <div class="line-name"><strong>Антивирус</strong></div>
                            <p class="line-description">Преградит вход в защищаемую систему вирусам и вредоносному ПО,
                                вылечит
                                от уже проникших вирусов.</p></td>
                        <td class="p-text-center"><i class="pi pi-check"></i></td>
                        <td class="p-text-center"><i class="pi pi-check"></i></td>
                        <td class="p-text-center"><i class="pi pi-check"></i></td>
                    </tr>
                    <tr class="dedicated">
                        <td>
                            <div class="line-name"><strong>Веб-антивирус</strong></div>
                            <p class="line-description">Обеспечит проверку веб-страниц в режиме реального времени,
                                блокирует
                                фишинговые и другие опасные интернет-ресурсы.</p></td>
                        <td class="p-text-center"><i class="pi pi-check"></i></td>
                        <td class="p-text-center"><i class="pi pi-check"></i></td>
                        <td class="p-text-center"><i class="pi pi-check"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <div class="line-name"><strong>Превентивная защита</strong></div>
                            <p class="line-description">Защита от новейших активных угроз, целевых атак и попыток
                                проникновения, в том числе через уязвимости «нулевого дня».</p></td>
                        <td class="p-text-center"><i class="pi pi-check"></i></td>
                        <td class="p-text-center"><i class="pi pi-times"></i></td>
                        <td class="p-text-center"><i class="pi pi-times"></i></td>
                    </tr>
                    <tr class="dedicated">
                        <td>
                            <div class="line-name"><strong>Защита от потери данных</strong></div>
                            <p class="line-description">Убережет от повреждения файлы, зашифрованные
                                троянцем-шифровальщиком,
                                создавая регулярно их резервные копии. (<span
                                    class="dedicated-text">отключена по умолчанию</span>)</p></td>
                        <td class="p-text-center"><i class="pi pi-check"></i></td>
                        <td class="p-text-center"><i class="pi pi-times"></i></td>
                        <td class="p-text-center"><i class="pi pi-times"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <div class="line-name"><strong>Антиспам</strong></div>
                            <p class="line-description">С высокой долей вероятности распознает спам независимо от языка
                                сообщения, при близком к нулю проценте ложных срабатываний.</p></td>
                        <td class="p-text-center"><i class="pi pi-check"></i></td>
                        <td class="p-text-center"><i class="pi pi-times"></i></td>
                        <td class="p-text-center"><i class="pi pi-times"></i></td>
                    </tr>
                    <tr class="dedicated">
                        <td>
                            <div class="line-name"><strong>Родительский контроль</strong></div>
                            <p class="line-description">Блокирует сайты по ключевым словам в URL и защитит детей от
                                посещения
                                нежелательных веб-ресурсов. Функция запрета доступа к файлам, папкам и сменным носителям
                                сделает невозможным несанкционированное использование, удаление или похищение
                                информации.</p></td>
                        <td class="p-text-center"><i class="pi pi-check"></i></td>
                        <td class="p-text-center"><i class="pi pi-times"></i></td>
                        <td class="p-text-center"><i class="pi pi-times"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <div class="line-name"><strong>Брандмауэр</strong></div>
                            <p class="line-description">Возведет заслон на пути попыток хакеров вторгнуться в ваш
                                компьютер.</p></td>
                        <td class="p-text-center"><i class="pi pi-check"></i></td>
                        <td class="p-text-center"><i class="pi pi-times"></i></td>
                        <td class="p-text-center"><i class="pi pi-times"></i></td>
                    </tr>
                    <tr class="dedicated">
                        <td>
                            <div class="line-name"><strong>Блокировка съемных устройств</strong></div>
                            <p class="line-description">Блокировки доступа к съемным носителям — флеш-картам и любым
                                устройствам, которые используют USB-порт для подключения к ПК, включая веб-камеры,
                                фотоаппараты и MP3-плееры.</p></td>
                        <td class="p-text-center"><i class="pi pi-check"></i></td>
                        <td class="p-text-center"><i class="pi pi-times"></i></td>
                        <td class="p-text-center"><i class="pi pi-times"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <div class="line-name"><strong>Облако Dr.Web</strong></div>
                            <p class="line-description">Проверка URL в режиме реального времени на серверах компании
                                «Доктор
                                Веб».</p></td>
                        <td class="p-text-center"><i class="pi pi-check"></i></td>
                        <td class="p-text-center"><i class="pi pi-times"></i></td>
                        <td class="p-text-center"><i class="pi pi-times"></i></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="p-col-12">
                <p><a href="/mobile" class="link-mobile attention-border">А также все компоненты защиты тарифа Dr.Web
                    Мобильный</a>
                </p>
                <h3>Поддерживаемые ОС</h3>
                <ul>
                    <li class="p-mb-2"><i class="pi pi-check attention p-mr-2"></i> Windows 10/8.1/8/7/Vista/XP</li>
                    <li class="p-mb-2"><i class="pi pi-check attention p-mr-2"></i> MacOS 10.8-10.15 и выше</li>
                    <li class="p-mb-2"><i class="pi pi-check attention p-mr-2"></i> Linux ядро 2.6.37 и выше, glibc от
                        2.13 и выше
                    </li>
                    <li class="p-mb-2"><i class="pi pi-check attention p-mr-2"></i> Android 4.4+</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "primevue/button";
import {ref} from "vue";
import Dialog from "primevue/dialog";
import Checkbox from "primevue/checkbox";

export default {
    name: "AntivirusPremium",

    setup() {
        const confirmChecked = ref(false);
        const displayDrWebPrem = ref(false);

        const openDrWebPrem = () => {
            displayDrWebPrem.value = true;
        };
        const closeDrWebPrem = () => {
            displayDrWebPrem.value = false;
        };

        return {
            displayDrWebPrem,
            confirmChecked,
            openDrWebPrem,
            closeDrWebPrem
        }
    },

    components: {
        Dialog,
        Button,
        Checkbox
    }
}
</script>

<style scoped>
li {
    list-style-type: none;
}

ul {
    padding-left: 0;
}

.connect > .p-button {
    background-color: #c9302c;
    color: white;
    border: none;
}

.connect > .p-button:enabled:hover {
    background-color: #ac2925;
    color: white;
}

.message-connect {
    background-color: #a94442;
}

.dr-web-price {
    color: #d82042;
    margin-bottom: 6px;
    font-size: 18px;
}

.font-price {
    font-size: 30px;
}

.attention {
    color: #68b905;
}

li a, .link-mobile {
    text-decoration: none;
    color: #68b905;
}

.list-instructions {
    padding: 20px 10px;
    background-color: #f2f2f2;
}

.table > tbody > tr > th, .table > thead > tr > th, .table > tr > th {
    background-color: #969799;
    color: #fff;
    padding: 8px;
}

.table > thead {
    border-color: #969799;
}

.table, .table-bordered tr td {
    border: 1px solid #d7d7d7;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

.table > tbody, .table > tbody > tr, .table > thead, .table > thead > tr {
    vertical-align: inherit;
    border: inherit;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > thead > tr > td, .table > thead > tr > th, .table > tr > td, .table > tr > th {
    border-bottom: 1px solid #e3e4e4;
    border-right: 1px solid #cfcfcf;
    border-left: 1px solid #cfcfcf;
}

tbody tr:nth-child (even) {
    background-color: #B4DAF2;
}

.dedicated > tbody > tr:nth-of-type(2n+1) > td, .dedicated > td {
    background-color: #eff0f0;
}

.dedicated-text {
    color: #d82042;
}

.tableHeaderWidth {
    width: 10%;
}

td .pi-check, td .pi-times {
    font-size: 2rem;
    padding-top: 15px;
}

.pi-times {
    color: #d82042;
}

.attention-border:hover {
    border-bottom: 1px dashed #68b905;
}

.mark-link {
    text-decoration: none;
    color: #337ab7;
}

@media (max-width: 768px) {
    .line-description {
        display: none;
    }

    .table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
        padding: 5px;
    }
}

@media (max-width: 380px) {
    .table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
        padding: 2px;
    }

    .line-name {
        font-size: 12px;
    }
}

</style>