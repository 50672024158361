<template>
    <div class="p-grid">
        <div class="p-col-12">
            <h1 class="p-text-center">Выберите услугу</h1>
            <h4 class="p-text-center">Выберите тариф Dr.Web и оформите подписку</h4>
            <div class="p-lg-8 p-md-12 p-lg-offset-2 p-md-offset-0">
                <p>
                    Подключайте услугу «Антивирус Dr.Web» всего за несколько минут — подписаться проще, чем
                    "заразиться"!
                    Управлять услугой «Антивирус Dr.Web» просто — активируйте необходимое количество подписок,
                    расширяйте
                    защиту Dr.Web на новые ПК/Mac и мобильные устройства или сокращайте количество подписок,
                    а также переходите на другой тариф.
                    Подписка предоставляется на срок от 1 месяца. Максимальный срок подписки неограничен — фактически
                    это
                    «вечный антивирус». Заботиться о продлении не нужно — подписка продлевается автоматически, пока вы
                    сами
                    не откажетесь от нее.
                </p>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-lg-3 p-md-6 p-sm-12 p-col-12 p-lg-offset-3 p-md-offset-0">
                    <div class="p-text-center dr-bg">
                        <router-link to="mobile"><img src="@/assets/img/antivirus/mobile.png" alt="dr-web-mobile">
                        </router-link>
                        <h4 class="text-center">Dr.WEB<br> МОБИЛЬНЫЙ</h4>
                    </div>
                    <p class="p-text-center"><a href="/mobile" class="dr-web-link">Подробнее</a></p><br>
                    <p class="p-text-center">Защита<br> для Android</p><br>
                    <p class="dr-web-price p-text-center">
                        <strong>
                            <span class="font-price">49</span> руб./месяц
                        </strong>
                    </p>
                    <div class="connect">
                        <Button label="Подключится" @click="openDrWebMobi"/>
                        <Dialog
                            header="Подключение услуги"
                            v-model:visible="displayDrWebMobi"
                            :modal="true"
                            :dismissableMask="true"
                            :style="{width: '700px'}"
                        >
                            <div class="p-fluid p-formgrid p-grid p-pt-1">
                                <div class="p-col-12 message-connect p-text-center p-mt-3 p-mb-4">
                                    <h4>
                                        Вы можете оставить заявку, нажав кнопку "Подключить"!
                                    </h4>
                                </div>
                                <h4>Активация</h4>
                                <p>Подписка производится на любой срок. Услуга начинает действовать немедленно после
                                    активации подписки
                                    и
                                    установки ПО Dr.Web. На лицевом счете абонента должна находиться сумма, необходимая
                                    для оплаты услуги
                                    за
                                    остаток текущего месяца. <a href="/finance" class="mark-link" target="_blank">Способы
                                        оплаты.</a></p>
                                <h4>Продление</h4>
                                <p>Заботиться о продлении не нужно — подписка продлевается автоматически при
                                    своевременной оплате
                                    услуг.</p>
                                <h4>Отказ от услуги</h4>
                                <p>
                                    В любой момент можно отказаться от пользования услугой. Она останется активной
                                    только до конца
                                    оплаченного периода. При возобновлении клиентом подписки на услугу ссылка на
                                    скачивание установочного
                                    файла вновь становится доступна.
                                </p>
                                <Checkbox id="binary" v-model="confirmChecked" :binary="true"/>
                                <label for="binary"> Я принимаю условия <a href="pdf/license_avdesk.pdf"
                                                                           class="mark-link"
                                                                           target="_blank">лицензионного договора об
                                    использовании
                                    програмного обеспечения ООО "Доктор Веб"</a>
                                </label>
                            </div>
                            <template #footer>
                                <Button label="Подключить" icon="pi pi-check" @click="closeDrWebMobi"
                                        :disabled="!confirmChecked"/>
                            </template>
                        </Dialog>
                    </div>
                </div>
                <div class="p-lg-3 p-md-6 p-sm-12 p-col-12 p-lg-offset-left-3 p-md-offset-0">
                    <div class="p-text-center dr-bg">
                        <a href="/premium"><img src="@/assets/img/antivirus/premium.png" alt="dr-web-premium"></a>
                        <h4 class="text-center">Dr.WEB<br> ПРЕМИУМ</h4>
                    </div>
                    <p class="p-text-center"><a href="/premium" class="dr-web-link">Подробнее</a></p><br>
                    <p class="p-text-center">Максимальная защита<br> для Windows и Android</p><br>
                    <p class="dr-web-price p-text-center">
                        <strong>
                            <span class="font-price">99</span> руб./месяц
                        </strong>
                    </p>
                    <div class="connect">
                        <Button label="Подключится" @click="openDrWebPrem"/>
                        <Dialog
                            header="Подключение услуги"
                            v-model:visible="displayDrWebPrem"
                            :modal="true"
                            :dismissableMask="true"
                            :style="{width: '700px'}"
                        >
                            <div class="p-col-12 message-connect p-text-center p-mt-3 p-mb-4">
                                <h4>
                                    Вы можете оставить заявку, нажав кнопку "Подключить"!
                                </h4>
                            </div>
                            <h4>Активация</h4>
                            <p>Подписка производится на любой срок. Услуга начинает действовать немедленно после
                                активации подписки и
                                установки ПО Dr.Web. На лицевом счете абонента должна находиться сумма, необходимая для
                                оплаты услуги за
                                остаток текущего месяца. <a href="/finance" class="mark-link" target="_blank">Способы
                                    оплаты.</a></p>
                            <h4>Продление</h4>
                            <p>Заботиться о продлении не нужно — подписка продлевается автоматически при своевременной
                                оплате
                                услуг.</p>
                            <h4>Отказ от услуги</h4>
                            <p>
                                В любой момент можно отказаться от пользования услугой. Она останется активной только до
                                конца
                                оплаченного периода. При возобновлении клиентом подписки на услугу ссылка на скачивание
                                установочного
                                файла вновь становится доступна.
                            </p>
                            <Checkbox id="binary" v-model="confirmChecked" :binary="true"/>
                            <label for="binary"> Я принимаю условия <a href="pdf/license_avdesk.pdf" class="mark-link"
                                                                       target="_blank">лицензионного договора об
                                использовании
                                програмного обеспечения ООО "Доктор Веб"</a>
                            </label>
                            <template #footer>
                                <Button label="Подключить" icon="pi pi-check" @click="closeDrWebPrem"
                                        :disabled="!confirmChecked"/>
                            </template>
                        </Dialog>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from "primevue/button";
import Checkbox from 'primevue/checkbox';
import {ref} from "vue";

export default {
    name: "Antivirus",

    setup() {
        const confirmChecked = ref(false);
        const displayDrWebMobi = ref(false);
        const displayDrWebPrem = ref(false);

        const openDrWebMobi = () => {
            displayDrWebMobi.value = true;
        };
        const closeDrWebMobi = () => {
            displayDrWebMobi.value = false;
        };

        const openDrWebPrem = () => {
            displayDrWebPrem.value = true;
        };
        const closeDrWebPrem = () => {
            displayDrWebPrem.value = false;
        };

        return {
            displayDrWebMobi,
            displayDrWebPrem,
            confirmChecked,
            openDrWebMobi,
            closeDrWebMobi,
            openDrWebPrem,
            closeDrWebPrem
        }
    },

    components: {
        Dialog,
        Button,
        Checkbox
    }
}
</script>

<style scoped>
.dr-web-link {
    color: #68b905;
    text-decoration: none;
}

.dr-web-link:hover {
    color: #68b905;
    border-bottom: 1px dashed #68b905;
}

.dr-web-price {
    color: #d82042;
    margin-bottom: 6px;
    font-size: 18px;
}

.font-price {
    font-size: 22px;
}

.connect > .p-button {
    width: 100%;
    background-color: #c9302c;
    color: white;
    border: none;
}

.connect > .p-button:enabled:hover {
    width: 100%;
    background-color: #ac2925;
    color: white;
}

.message-connect {
    background-color: #a94442;
}

.mark-link {
    text-decoration: none;
    color: #337ab7;
}

.dr-bg {
    background: url('../../assets/img/antivirus/bg_grey.png') repeat-x 0 bottom;
}
</style>