import {ref} from "vue";
import changeEmail from "@/api/changeContactData/changeEmail";
import confirmEmail from "@/api/changeContactData/confirmEmail";
import {useField, useForm} from "vee-validate";
import * as yup from "yup";

export function useChangeEmail() {
    const emailSent = ref(false);
    const displayEmailModal = ref(false);

    const closeEmailModal = () => {
        displayEmailModal.value = false;
    }
    const openEmailModal = () => {
        displayEmailModal.value = true;
    };

    const {handleSubmit, resetForm} = useForm()

    const sendEmail = handleSubmit((values) => {
        changeEmail(values).then((response) => {
            if (response.request.readyState === 4) {
                emailSent.value = true
            }
        })
    })

    const sendEmailCode = () => {
        confirmEmail(emailCode.value).then((response) => {
            if (response.request.readyState === 4) {
                resetEmailForm()
            }
        })
    }

    const resetEmailForm = () => {
        emailSent.value = false;
        displayEmailModal.value = false;
    }

    const {value: newEmail, errorMessage: newEmailErrorMessage} = useField('new-email',
        yup
            .string()
            .email('Введите корректный email')
            .trim()
            .required('Поле не может быть пустым')
    )

    const {value: emailCode, errorMessage: emailCodeErrorMessage} = useField('email-code',
        yup
            .string()
            .trim()
    )

    return {
        newEmail,
        newEmailErrorMessage,
        emailCode,
        emailCodeErrorMessage,
        displayEmailModal,
        emailSent,
        sendEmail,
        sendEmailCode,
        resetEmailForm,
        openEmailModal,
        closeEmailModal,
        resetForm
    }
}