import {instanceAxios} from "@/axios/axios";
import cookie from "js-cookie";

export default function postModalNewSupported(data) {
    return new Promise((resolve, reject) => {
        instanceAxios.post('addSupported', data, {params: {userToken: cookie.get('token')}})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}