<template>
    <div class="p-mr-1 p-mt-2 p-mb-2 p-ml-1">
        <Card class="offer" :class="product.color">
            <template #title>
                <div class="title-card-offer">
                    {{ product.title }}
                </div>
            </template>
            <template #content>
                <Divider></Divider>
                <div class="p-grid body-card-offer">
                    <div class="p-col body-card-image-offer" v-if="product.image">
                        <img class="card-image" alt="user header" :src="product.image">
                    </div>
                    <div class="p-col offers-card-content">
                        {{ product.content }}
                    </div>
                </div>
                <Divider></Divider>
            </template>
            <template #footer>
                <div class="p-text-right">
                    <Button icon="pi pi-info" label="Подробнее"/>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import Card from 'primevue/card';
import Button from 'primevue/button';
import Divider from 'primevue/divider';

export default {
    props: ['product'],

    components: {
        Card,
        Button,
        Divider,
    }
}
</script>

<style scoped>
.card-image {
    width: 170px;
}

.offers-card-content {
    min-height: 140px;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
}

@media (max-width: 450px) {
    .body-card-offer {
        flex-direction: column;
    }

    .body-card-image-offer {
        text-align: center;
    }

    .offers-card-content {
        font-size: 14px;
        min-height: auto;
    }

    .title-card-offer {
        font-size: 1.1rem;
    }
}
</style>