<template>
    <Message :severity="type" :closable="closable" :sticky="!autoClose" :life="life">
        <div class="p-grid p-align-center content-message">
            <div class="p-col-12 p-xl-3 p-lg-3 p-md-12">
                <h4 class="p-ml-4 p-text-bold p-text-uppercase title-message">{{ header }}</h4>
            </div>
            <div class="p-col-12 p-xl-6 p-lg-6 p-md-12">
                <p class="p-ml-4 text-message">{{ text }}
                    <slot name="timer"></slot>
                </p>
            </div>
            <div v-if="button" class="p-col-12 p-xl-3 p-lg-3 p-md-12 p-mt-2">
                <Button :label="button.text" :class="'p-button-raised p-button-' + button.type"
                        @click="$emit('handleClick')"/>
            </div>
        </div>
    </Message>
</template>

<script>
import {computed} from "vue";
import Message from 'primevue/message';
import Button from 'primevue/button';

export default {
    name: "CustomMessage",
    props: {
        type: {
            type: String,
            default: 'info'
        },
        header: {
            type: String,
            default: 'Вы не передали заголовок'
        },
        text: {
            type: String,
            default: 'Вы не передали текст'
        },
        button: {
            type: Object,
        },
        closable: {
            type: Boolean,
            default: false
        },
        autoClose: {
            type: Boolean,
            default: false
        },
        delay: {
            type: String,
            default: "3000"
        }
    },
    setup(props) {

        return {
            life: computed(() => +props.delay)
        }
    },
    components: {
        Message,
        Button,
    }
}
</script>
<style scoped>
.text-message {
    margin-bottom: 0;
    margin-top: 4px;
}

.title-message {
    margin: 4px 0 0 0;
}

.p-message {
    margin: 0;
}

@media (max-width: 992px) {
    .title-message {
        font-size: 1rem;
    }

    .title-message, .text-message {
        margin-left: 0 !important;
    }
}

@media (max-width: 950px) {
    .text-message {
        font-size: 0.9rem;
    }
}

@media (max-width: 900px) {
    .content-message {
        font-size: 0.9rem;
    }
}
</style>