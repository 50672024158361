<template>
    <div class="p-grid">
        <Toast/>
        <div class="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12">
            <ContactData
                :fullName="fullUserInfo.fullname"
                :email="fullUserInfo.email"
                :phone="fullUserInfo.phone"
            />
        </div>
        <div class="p-xl-6 p-lg-6 p-md-12 p-sm-12">
            <RegistrationData
                :documentType="fullUserInfo.documentType"
                :series="fullUserInfo.documentSeries"
                :number="fullUserInfo.documentID"
                :dateIssue="fullUserInfo.dateOfIssue"
                :placeIssue="fullUserInfo.issuedBy"
                :addressRegistration="fullUserInfo.address"
            />
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col">
            <CustomMessage
                type="info"
                header="Внимание!"
                text=" Уважаемый абонент, если по каким-то причинам у вас поменялись регистрационные данные, просим сообщить нам об этом через форму обратной связи"
                :button="{
                text:'Обновить данные',
                type: 'info',
            }"
                @handleClick="openModalCreatingNewAppeal"
            />
            <NewAppealModal
                :showToast="showToast"
                :isOpen="isOpen"
                @handleCloseModal="handleCloseModal"
            />
        </div>
    </div>
</template>

<script>

import {ref} from "vue";
import {computed} from "vue";
import {useToast} from "primevue/usetoast";
import getFullUserInfo from "@/api/fullUserInfo/getFullUserInfo"
import CustomMessage from "@/components/ui/CustomMessage";
import ContactData from "./ContactData";
import RegistrationData from "./RegistrationData";
import NewAppealModal from "./NewAppealModal";
import Toast from 'primevue/toast';

export default {
    setup() {

        const toast = useToast()
        const isOpen = ref(false)
        const fullUserInfo = ref({})

        const openModalCreatingNewAppeal = () => {
            isOpen.value = true
        };

        const showToast = (type, info, time) => {
            toast.add({severity: `${type}`, summary: `${info}`, life: `${time}`});
        }


        const handleCloseModal = () => {
            isOpen.value = false;

        }

        getFullUserInfo().then((response) => {
            fullUserInfo.value = response.data[0]
        })

        return {
            openModalCreatingNewAppeal,
            handleCloseModal,
            isOpen: computed(() => isOpen),
            showToast,
            fullUserInfo
        }
    },
    components: {
        CustomMessage,
        RegistrationData,
        ContactData,
        NewAppealModal,
        Toast,
    }
}
</script>

<style scoped>
.danger-info {
    background-color: #7e4444;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
}

.success-info {
    background-color: #3f773f;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
}
</style>