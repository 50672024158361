<template>
    <div class="p-grid p-jc-center vertical-container login-template">
        <HeaderLogin/>
        <div class="p-col-12">
            <div class="p-grid p-jc-center p-ai-center">
                <div class="p-xl-6 p-lg-8 p-md-10">
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <FooterLogin/>
    </div>
</template>

<script>
import HeaderLogin from "../components/ui/login/HeaderLogin";
import FooterLogin from "../components/ui/login/FooterLogin";

export default {
    components: {
        FooterLogin,
        HeaderLogin,
    }
}
</script>

<style scoped>
.login-template {
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    flex-wrap: nowrap;
    margin-left: 0;
    margin-right: 0;
}
</style>