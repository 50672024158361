<template>
    <Panel :header="item.title"
           :class="['widget-finance', {'clickable': item.available === true || item.enabled === true}]">
        <div :class="[Number(item.info) <= 0  ? 'red' : 'green']">{{ item.info }}
            <span v-if="item.enabled === true" class="green">Включено</span>
            <span v-if="item.enabled === false" class="red">Выключено</span>
            <span v-if="item.available === true" class="green">Доступен</span>
            <span v-if="item.available === false" class="red">Недоступен</span>
        </div>
    </Panel>
</template>

<script>
import Panel from 'primevue/panel';

export default {
    name: "WidgetFinance",
    props: ['item'],
    components: {
        Panel
    }
}
</script>

<style scoped>
.green {
    color: #056905;
}

.red {
    color: #ca2d2d;
}

.clickable {
    cursor: pointer;
}
</style>