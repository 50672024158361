<template>
    <div class="p-grid p-jc-center" v-if="isLoading">
        <div class="p-col-12">
            <Skeleton height="100px" borderRadius="16px"></Skeleton>
        </div>
        <div class="p-col-12">
            <Skeleton height="100px" borderRadius="16px"></Skeleton>
        </div>
        <div class="p-col-12">
            <Skeleton height="100px" borderRadius="16px"></Skeleton>
        </div>
    </div>
    <div class="p-grid p-jc-center" v-else>
        <div class="p-col-12" v-for="item in items" :key="item.id">
            <WidgetFinance :item="item"/>
        </div>
    </div>
</template>

<script>
import Skeleton from 'primevue/skeleton';
import WidgetFinance from "@/components/dashboard/widgets-finance/WidgetFinance";
import {useWidgetsFinance} from "./widgetsFinance";

export default {
    name: "WidgetsFinancePanel",

    setup() {
        return {
            ...useWidgetsFinance(),
        }
    },

    components: {
        Skeleton,
        WidgetFinance
    }
}
</script>

<style scoped>

</style>